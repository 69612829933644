<template>
  <div>
    <paper v-if="hasTradeDeals">
      <template #title>Offers</template>
      <template #actions>
        <ez-button-dropdown v-if="canEditProduct" class="btn-dropdown" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button @click="openTradeDealModal" type="link"> Edit </ez-button>
            <ez-button @click="openDeleteConfirmation" type="red-link"> Delete </ez-button>
          </template>
        </ez-button-dropdown>
      </template>

      <trade-deal-preview :tradeDeals="tradeDeals" :currency="product && product.currency" />
    </paper>
    <ez-button
      v-else
      :disabled="!canEditProduct"
      type="secondary"
      formType="button"
      isFullWidth
      @click="onAddTradeDealClick"
    >
      <font-awesome-icon icon="plus" />
      <span>Add Trade Deal</span>
    </ez-button>
    <trade-deal-modal
      ref="tradeDealModal"
      :product="product"
      :distributorId="distributorId"
      @success="onTradeDealSuccess"
    />
    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Delete this trade deal?</template>
      <template #content>
        <p>When deleted, trade deal will no longer be applied to this product.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteConfirmation" type="link"> Cancel </ez-button>
        <ez-button @click="confirmDelete" type="red" :isLoading="isDeleteLoading">
          Yes, Delete
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import { EzConfirmationModal } from '@/components/ui/Modal';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import Product from '@/models/Product';
import { isAdmin, LOADING_KEY } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import httpService from '@/api/http';
import TradeDealModal from './Modal.vue';
import TradeDealPreview from './Preview.vue';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    TradeDealPreview,
    TradeDealModal,
    EzConfirmationModal,
    Paper,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    distributorId: {
      type: Number,
      required: true,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      isAdmin: isAdmin(),
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    product() {
      return (
        Product.find(this.productId) || {
          deals: [],
        }
      );
    },
    tradeDeals() {
      return this.product.deals || [];
    },
    hasTradeDeals() {
      return !!this.tradeDeals.length;
    },
    canEditProduct() {
      return this.$permission.has('editProductPricing');
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
    actionURL() {
      if (this.isAdmin) {
        return `/admin/distributors/${this.distributorId}/products/${this.product.id}`;
      }
      return `/distributor/products/${this.product.id}`;
    },
    isDeleteLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT);
    },
  },
  methods: {
    openTradeDealModal() {
      this.$refs.tradeDealModal.open();
    },
    onAddTradeDealClick() {
      this.openTradeDealModal();
    },
    openDeleteConfirmation() {
      this.$refs.deleteModal.open();
    },
    closeDeleteConfirmation() {
      this.$refs.deleteModal.close();
    },
    async confirmDelete() {
      const data = new FormData();
      data.append('deals', '');
      const { data: productData } = await httpService.patch(this.actionURL, data, {
        headers: {
          loadingKey: LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
        },
      });
      Product.update({ data: productData.data }).then(() => {
        this.closeDeleteConfirmation();
      });
      flash.success({ title: 'Successfully deleted the trade deal.' });
    },
    onTradeDealSuccess(data) {
      this.$emit('success', data);
    },
  },
};
</script>

<style scoped lang="scss">
:deep() .btn-dropdown {
  .drp-btn {
    display: flex;
    justify-content: flex-end;
  }
}
:deep() .overview-paper {
  max-width: 100%;
}

:deep() .button-dropdown {
  justify-content: center;
  background: none;

  &:hover {
    background: none;
  }

  &__toggle-container {
    justify-content: stretch;
    align-items: stretch;
    background: none;

    .button {
      @extend %flex-center;
      background: none;
      flex: 1 1 auto;
      padding: 0;
      transition: none;

      &:hover {
        color: $color-gray-25;
        background: none;
      }

      &:active {
        background: none;
      }
    }
  }
}
</style>
