<template>
  <div>
    <ez-table
      :data="venues"
      :headers="headers"
      :columns="['name']"
    >
      <template #cell-name="{ row }">
        <div class="venue">
          <ez-entity-info :imgUrl="row.logo" img-border-radius="50%">
            <div
              class="venue-info"
              :title="row.name"
              :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.AVAILABILITY.TEXT__OUTLET_NAME}-${row.id}`"
            >
              {{ row.name }}
            </div>
          </ez-entity-info>

          <ez-button
            :type="row.isHidden ? 'secondary': 'green'"
            @click="showProductToVenue(row)"
            class="venue__button"
            :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.AVAILABILITY.BUTTON__SELECT}-${row.id}`"
          >
            <template v-if="!row.isHidden" >
              <font-awesome-icon icon="check"/>
            </template>
            {{row.isHidden ? 'Select' : 'Selected'}}
          </ez-button>
        </div>
      </template>
    </ez-table>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-load-more v-if="meta?.nextId && !isLoadingMore" @loadMore="onLoadMoreClick" />
  </div>
</template>


<script>

import { mapActions, mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { isAdmin, LOADING_KEY } from '@/util/constants';
import EzTable from '@/components/ui/Table';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzButton from '@/components/ui/Button/';
import EzSelectDeselect from '@/components/ui/SelectDeselect';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';

export default {
  components: {
    EzSpinner,
    EzLoadMore,
    EzButton,
    EzEntityInfo,
    EzTable,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    distributorId: {
      type: Number,
    },
  },
  data() {
    return {
      isAdmin: isAdmin(),
      venues: [],
      meta: {},
      headers: {
        name: (h) => {
          const headerName = h('span', null, `${this.$t('global.venue')} Name`);
          const checkbox = h(EzSelectDeselect, {
            on: {
              selectAll: this.selectAll,
              deselectAll: this.deselectAll,
            },
            props: {
              selectDataCy: supplierCy.PRODUCTS.SINGLE_PRODUCT.AVAILABILITY.BUTTON__SELECT_ALL,
              deselectDataCy: supplierCy.PRODUCTS.SINGLE_PRODUCT.AVAILABILITY.BUTTON__DESELECT_ALL,
            },
          });
          return h('div', { class: 'venue' }, [headerName, checkbox]);
        },
      },
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_ALL_VENUES_LOAD_MORE);
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'adminFetchAllVenuesForProducts',
      'adminHideVenueForProduct',
      'adminUnhideVenueForProduct',
      'adminHideAllVenuesForProduct',
      'adminUnhideAllVenuesForProduct',
    ]),
    ...mapActions('entities/products', [
      'distributorFetchVenuesForProduct',
      'distributorHideVenueForProduct',
      'distributorUnhideVenueForProduct',
      'distributorHideAllVenuesForProduct',
      'distributorUnhideAllVenuesForProduct',
    ]),
    updateProductAvailability(venue) {
      const { id, isHidden } = venue;
      const data = {
        productId: this.productId,
        venueId: id,
      };
      if (this.isAdmin) {
        data.distributorId = this.distributorId;
        return isHidden
          ? this.adminUnhideVenueForProduct(data)
          : this.adminHideVenueForProduct(data);
      }
      return isHidden
        ? this.distributorUnhideVenueForProduct(data)
        : this.distributorHideVenueForProduct(data);
    },
    async showProductToVenue(row) {
      const { isHidden: show } = row;
      try {
        await this.updateProductAvailability(row);
        row.isHidden = !show;
      } catch (e) {
        console.error(e);
      }
    },
    selectOrDeselectAll(state) {
      const data = {
        productId: this.productId,
      };
      if (this.isAdmin) {
        data.distributorId = this.distributorId;
        return state
          ? this.adminHideAllVenuesForProduct(data)
          : this.adminUnhideAllVenuesForProduct(data);
      }
      return state
        ? this.distributorHideAllVenuesForProduct(data)
        : this.distributorUnhideAllVenuesForProduct(data);
    },
    /**
     * Set isHidden for all venues.
     * This is used to select or deselect all.
     * @param {boolean} state
     */
    async setStateToEach(state) {
      try {
        await this.selectOrDeselectAll(state);
        this.venues.forEach((venue) => {
          if (venue.isHidden !== state) {
            venue.isHidden = !venue.isHidden;
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    selectAll() {
      this.setStateToEach(false);
    },
    deselectAll() {
      this.setStateToEach(true);
    },
    onLoadMoreClick() {
      this.refresh(LOADING_KEY.DISTRIBUTOR_FETCH_ALL_VENUES_LOAD_MORE);
    },
    async refresh(loadingKey = undefined) {
      const query = {
        ...(this.meta?.nextId ? { nextId: this.meta?.nextId } : {}),
        ...(this.meta?.nextValue ? { nextValue: this.meta?.nextValue } : {}),
        limit: 20,
      };
      const { data: { data, meta } } = this.isAdmin
        ? await this.adminFetchAllVenuesForProducts({
          distributorId: this.distributorId,
          productId: this.productId,
          query,
          ...(loadingKey ? { loadingKey } : {}),
        })
        : await this.distributorFetchVenuesForProduct({
          productId: this.productId,
          query,
          ...(loadingKey ? { loadingKey } : {}),
        });

      this.venues = [
        ...this.venues,
        ...data,
      ];
      this.meta = meta;
    },
  },
  created() {
    this.refresh();
  },
};
</script>


<style lang="scss" scoped>
  .venue {
    display: flex;
    justify-content: space-between;

    &__button {
      min-width: 108px;

      &.button--green {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    font-weight: 500;
  }
</style>
