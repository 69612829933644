<template>
  <div>
    <paper>
      <template #title>Main info</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editInfo">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info
        imgWidth="4rem"
        imgHeight="4rem"
        imgBorderRadius="50%"
        :imgUrl="distributor.logo"
        >{{ distributor.name }}</ez-entity-info
      >
      <div v-if="distributor.address" class="address">
        <div class="info info--vertical">
          <small>Street Address</small>
          <span>{{ distributor.address.street }}</span>
        </div>
        <div class="info info--vertical">
          <small>City</small>
          <span>{{ distributor.address.city || '-' }}</span>
        </div>
        <div class="info info--vertical">
          <small>Zip code</small>
          <span>{{ distributor.address.zipCode || '-' }}</span>
        </div>
        <div class="info info--vertical">
          <small>Country</small>
          <span>{{ distributor.address.country || '-' }}</span>
        </div>
      </div>
      <div v-if="workingHours" class="overview-item">
        <h5>Cut-Off Time</h5>
        <div class="info info--vertical">
          <small>Orders Received By</small>
          <div>
            <span>{{ workingHours.to }}</span>
          </div>
        </div>
        <div class="info info--vertical">
          <small>Will Be Delivered In</small>
          <div>
            <span>{{ distributor.deliveryPeriodCount }} {{ deliveryPeriodCopy }}</span>
          </div>
        </div>
        <div class="info info--vertical">
          <small>Time Zone</small>
          <span>{{ distributor.timezone }}</span>
        </div>
      </div>
      <div v-if="tax || minimumOrderAmount" class="overview-item">
        <div v-if="tax" class="info info--vertical">
          <small>Tax:</small>
          <div>{{ tax }}%</div>
        </div>
        <div v-if="minimumOrderAmount" class="info info--vertical">
          <small>Minimum order amount:</small>
          <div>{{ minimumOrderAmount | price }}</div>
        </div>
      </div>
      <div v-if="hasNotifications" class="info info--vertical">
        <h4>Receive Notifications Via:</h4>
        <template v-if="distributor.email">
          <small>Email</small>
          <span>{{ distributor.email }}</span>
        </template>
        <template v-if="distributor.smsNumber">
          <small>SMS</small>
          <span>{{ distributor.smsNumber }}</span>
        </template>
        <template v-if="distributor.whatsAppNumber">
          <small>WhatsApp</small>
          <span>{{ distributor.whatsAppNumber }}</span>
        </template>
      </div>
    </paper>
    <paper>
      <template #title>Users</template>
      <div v-for="user in distributor.users" :key="user.id" class="info info--horizontal">
        <span>{{ user.name }}</span>
        <small>Admin</small>
      </div>
    </paper>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { pluralize } from '@/util/utils';
import { DISTRIBUTOR_STEP_1 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    EzEntityInfo,
  },
  computed: {
    distributor() {
      return this.draft;
    },
    hasSecondaryContact() {
      return this.distributor.secondaryContactName || this.distributor.secondaryContactPhone;
    },
    hasNotifications() {
      const { distributor } = this;
      return distributor.email || distributor.smsNumber || distributor.whatsAppNumber;
    },
    workingHours() {
      return this.distributor.workingHours || {};
    },
    tax() {
      return this.distributor.tax;
    },
    minimumOrderAmount() {
      return this.distributor.minimumOrderAmount;
    },
    ...mapState('entities/distributors', ['draft']),
    deliveryPeriodCopy() {
      return `Delivery ${pluralize('Day', this.distributor.deliveryPeriodCount)}`;
    },
  },
  methods: {
    onNextStep() {
      this.CLEAR_DRAFT();

      this.$router.push({
        name: 'manage-accounts',
        params: {
          flash: {
            title: `${this.$t('global.distributor')} created!`,
            type: 'success',
          },
        },
      });
    },
    editInfo() {
      this.$emit('stepBack', DISTRIBUTOR_STEP_1);
    },
    ...mapMutations('entities/distributors', ['CLEAR_DRAFT']),
  },
  mounted() {
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
.address {
  @include separator('both');
}
.overview-item {
  @include separator('bottom');
  h5 {
    line-height: px-to-rem(16px);
    font-size: px-to-rem(14px);
    font-weight: 500;
    letter-spacing: -0.25px;
  }
}
:deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: 0.75rem 0 0;
    justify-content: center;
  }
}
</style>
