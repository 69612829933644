<template>
  <div>
    <single-page v-if="isProductLoaded">
      <template #breadcrumbs>
        <template v-if="!isAdmin">
          <router-link :to="{ name: 'distributor-products' }">
            <font-awesome-icon icon="arrow-left" />
            Back to Products
          </router-link>
        </template>
        <template v-else>
          <router-link :to="{ name: 'admin-distributors' }">
            {{ $t('global.distributors') }}
          </router-link>
          <span>/</span>
          <router-link :to="{ path: `/suppliers/${distributorId}` }">
            {{ distributor && distributor.name }}
          </router-link>
          <span>/</span>
          <router-link :to="{ path: `/suppliers/${distributorId}/products` }">
            Products
          </router-link>
        </template>
      </template>
      <template #title>
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="product.image">
          <h1 class="limit-title" :title="product.name">{{ product.name }}</h1>
        </ez-entity-info>
      </template>
      <template #actions>
        <ez-button-dropdown v-if="canCreateDeleteProduct" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button @click="openDeleteModal" type="red-link">Delete Product </ez-button>
          </template>
        </ez-button-dropdown>
      </template>
      <template #navigation>
        <ul>
          <li>
            <router-link
              :to="{
                name: isAdmin ? 'admin-distributor-product-info' : 'distributor-product-single',
                params: { ...(isAdmin ? { productId: product.id } : {}) },
              }"
            >
              Product Info
            </router-link>
          </li>
          <li v-if="!isAdmin && me.inventoryTracking">
            <router-link :to="{ name: 'distributor-product-inventory-history' }">
              Inventory History
            </router-link>
          </li>
          <li v-if="!isAdmin && me.inventoryTracking">
            <router-link :to="{ name: 'distributor-product-inventory-overview' }">
              Inventory Overview
            </router-link>
          </li>
          <li v-if="!isAdmin && me.inventoryTracking">
            <router-link :to="{ name: 'distributor-product-par-level' }">
              Inventory Par Level
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: isAdmin
                  ? 'admin-distributor-product-tiers'
                  : 'distributor-product-single-tier',
                params: { ...(isAdmin ? { productId: product.id } : {}) },
              }"
              >Pricing
            </router-link>
          </li>
          <li v-if="canHideProducts">
            <router-link
              :to="{
                name: isAdmin
                  ? 'admin-distributor-product-availability'
                  : 'distributor-product-availability',
                params: { ...(isAdmin ? { productId: product.id } : {}) },
              }"
              >Availability
            </router-link>
          </li>
        </ul>
      </template>

      <ez-confirmation-modal ref="deleteModal" type="red">
        <template #title>Delete this product?</template>
        <template #content>
          <p>When deleted, this product will no longer be live on {{ platformName }}.</p>
        </template>
        <template #footer>
          <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
          <ez-button @click="confirmDeleteProduct" type="red">Yes, Delete</ez-button>
        </template>
      </ez-confirmation-modal>
    </single-page>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzEntityInfo from '@/components/ui/EntityInfo';
import SinglePage from '@/views/admin/SinglePage.vue';
import Product from '@/models/Product';
import EzLoader from '@/components/ui/Loader';
import Distributor from '@/models/Distributor';
import { isAdmin, LOADING_KEY } from '@/util/constants';

/**
 * Single
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    SinglePage,
    EzEntityInfo,
    EzLoader,
    EzButton,
    EzButtonDropdown,
    EzConfirmationModal,
  },
  data() {
    return {
      isAdmin: isAdmin(),
      distributorId: this.$route.params.id,
      productId: isAdmin() ? this.$route.params.productId : this.$route.params.id,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['isSomeLoading']),
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    me() {
      return this.loggedUser.distributor;
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_PRODUCT,
        LOADING_KEY.DISTRIBUTOR_FETCH_ALL_VENUES,
        LOADING_KEY.DELETE_DISTRIBUTOR_DEFAULT_TIER_PRICING,
        LOADING_KEY.DELETE_DISTRIBUTOR_SPECIAL_TIER_PRICING,
        LOADING_KEY.DISTRIBUTOR_HIDE_VENUE_FOR_PRODUCT,
        LOADING_KEY.DISTRIBUTOR_UNHIDE_VENUE_FOR_PRODUCT,
        LOADING_KEY.DISTRIBUTOR_HIDE_ALL_VENUES_FOR_PRODUCT,
        LOADING_KEY.DISTRIBUTOR_UNHIDE_ALL_VENUES_FOR_PRODUCT,
        LOADING_KEY.REMOVE_DISTRIBUTOR_PRODUCT,

        LOADING_KEY.ADMIN_FETCH_VENUES_FOR_PRODUCT,
        LOADING_KEY.ADMIN_HIDE_VENUE_FOR_PRODUCT,
        LOADING_KEY.ADMIN_UNHIDE_VENUE_FOR_PRODUCT,
        LOADING_KEY.ADMIN_HIDE_ALL_VENUES_FOR_PRODUCT,
        LOADING_KEY.ADMIN_UNHIDE_ALL_VENUES_FOR_PRODUCT,
      ]);
    },
    isProductLoaded() {
      return Object.keys(this.product).length !== 0;
    },
    distributor() {
      return Distributor.find(this.distributorId);
    },
    product: {
      get() {
        return Product.find(this.productId) || {};
      },
      set() {},
    },
    canCreateDeleteProduct() {
      return this.$permission.has('createDeleteProduct');
    },
    canHideProducts() {
      return this.$permission.has('hideProducts');
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'fetchDistributorProduct',
      'removeDistributorProduct',
      'fetchProductForDistributor',
      'removeProductForDistributor',
      'removeProductImageForDistributor',
    ]),
    ...mapActions('entities/categories', ['fetchCategories']),
    ...mapActions('entities/users', ['initDistributor']),
    openDeleteModal() {
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
    },
    async confirmDeleteProduct() {
      const { productId, distributorId } = this;
      await (this.isAdmin
        ? this.removeProductForDistributor({
            productId,
            distributorId,
          })
        : this.removeDistributorProduct({ id: this.productId }));
      this.closeDeleteModal();
      this.$router.push({
        name: this.isAdmin ? 'admin-distributor-products' : 'distributor-products',
        params: {
          flash: {
            type: 'success',
            title: 'Product successfully removed!',
          },
        },
      });
    },
  },
  async mounted() {
    if (!this.isProductLoaded && !this.isAdmin) {
      await this.initDistributor({ includeVenues: false });
      await this.fetchDistributorProduct({ id: this.productId });
    } else if (this.isAdmin) {
      await this.fetchProductForDistributor({
        id: this.distributorId,
        productId: this.productId,
        loadingKey: '',
      });
    }
    this.product = Product.find(this.productId);
  },
};
</script>

<style lang="scss" scoped>
:deep() .admin-single-page__content {
  .tiers,
  .p-info {
    max-width: px-to-rem(500px);
  }
}

.limit-title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

:deep() .loader {
  @extend %whole-screen;
}
</style>
