<template>
  <ul class="tier-preview">
    <li v-for="(deal, i) in tradeDeals" :key="i">
      <span class="tier-preview__quantity"> Buy {{ deal.buy }} </span>
      <span class="tier-preview__quantity"> Get {{ deal.get }} </span>
      <span class="tier-preview__quantity">
        For
        <v-price
          class="tier-preview__price"
          :price="deal.price || 0"
          :currency="currency"
          :show-market-price-info="true"
          :show-market-price-long="true"
        />
      </span>
    </li>
  </ul>
</template>

<script>
import VPrice from '@/components/v3/elements/VPrice';

export default {
  components: { VPrice },
  props: {
    tradeDeals: {
      type: Array,
      default: () => [],
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped lang="scss">
$item-height: 2.25rem;

.tier-preview {
  @include font-size(14px, 16px);
  @extend %ul-reset;
  li {
    @extend %flex-center;
    height: $item-height;
    justify-content: space-between;
  }

  &__quantity {
    display: flex;
  }

  &__price {
    :deep() .v-price__price {
      @include font-size(14px, 16px);
      font-weight: normal;
      margin-left: 4px;
    }
  }
}
</style>
