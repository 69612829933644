var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ez-user-list',{attrs:{"role":_vm.userRole,"users":[{
      ...this.loggedUser,
      role: {
        ...this.loggedUser.role,
        name: 'Admin',
      },
      venue: { name: 'All' }
    }],"columns":['name', 'email', 'role', 'venue'],"columnProps":{
      venue: { class: 'venue-cell' },
      role: { class: 'role-cell' },
    },"headers":{
      accountName: () => 'Venue',
      venue: () => _vm.$t('global.venue').toLowerCase(),
    },"isEditable":false,"canAddNewUser":false}}),_c('div',{staticClass:"has-admin-note"},[_c('font-awesome-icon',{attrs:{"icon":"info-circle"}}),_c('span',[_vm._v("Note: You will be able to add more users later within the account.")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }