<template>
  <div class="mt-24">
    <div class="map__actions mb-24">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="product-search"
          name="search"
          label="Search"
          placeholder="Search for an Account"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
      <ez-simple-dropdown placeholder="Create New Account" class="create-new-account">
        <template #dropdown>
          <ez-button class="create-new-account__btn" isFullWidth type="link">
            <router-link :to="{ name: 'manage-accounts-new-distributor' }">
              Create New {{ $t('global.distributor') }}
            </router-link>
          </ez-button>
          <ez-button class="create-new-account__btn" isFullWidth type="link">
            <router-link :to="{ name: 'manage-accounts-new-outlet' }">
              Create New Outlet Group
            </router-link>
          </ez-button>
        </template>
      </ez-simple-dropdown>
    </div>
    <ez-table
      :data="accounts"
      v-if="accounts.length"
      :columns="['name', 'role', 'platform', 'subscription', 'status', 'moreActions']"
      :headers="{
        name: () => 'Account',
        moreActions: () => '',
      }"
      :columnProps="{
        moreActions: { class: 'actions-cell small-cell' },
        status: { class: 'medium-cell' },
        subscription: { class: 'medium-cell' },
        platform: { class: 'medium-cell' },
        role: { class: 'medium-cell' },
      }"
      @rowClick="redirectToOtherAccount"
      :loading="isTableDataLoading"
    >
      <template #cell-name="{ row }">
        <v-entity-info :imageUrl="row.logo" imageBorderRadius="50%" :imageHasBorder="true">
          <span>{{ row.name }}</span>
          <template #suffix>
            <ez-premium-badge v-if="row.subscription === 'Premium'" />
            <v-badge v-if="row.isDefault" class="ml-8">Default</v-badge>
          </template>
        </v-entity-info>
      </template>
      <template #cell-role="{ row }">{{ row.role.name }}</template>
      <template #cell-platform="{ row }">
        <span v-if="row.platform === 'orderez'">OrderEZ</span>
        <span v-else>Foodrazor</span>
      </template>
      <template #cell-status="{ row }">
        <status-badge :status="row.status" />
      </template>
      <template #cell-moreActions="{ row }">
        <div @click.stop>
          <ez-button-dropdown buttonType="secondary" class="ml-16" @click.stop>
            <template #icon>
              <font-awesome-icon icon="ellipsis-h" />
            </template>
            <template #dropdown>
              <span class="label">Manage Account</span>
              <ez-button v-if="!row.isDefault" type="link" @click="makeDefault(row)">
                Make Default
              </ez-button>
              <ez-button type="link" @click="goToUserManagement(row)"> User Management </ez-button>
              <ez-button type="link" @click="goToSettings(row)"> Settings </ez-button>
              <ez-button
                :disabled="row.isDefault"
                v-tooltip="{
                  content: `${row.isDefault ? 'Please make another organization default' : ''}`,
                  classes: ['tooltip-general', 'tooltip--reset-margin'],
                  placement: 'top',
                }"
                type="link"
                class="red"
                @click="leaveOrganization(row)"
              >
                Leave Organization
              </ez-button>
            </template>
          </ez-button-dropdown>
        </div>
      </template>
    </ez-table>
    <empty-state v-else>
      <template #badge>
        <img src="@/assets/no-venue-empty-state.svg" width="160" alt="" />
      </template>
      <template #title>No accounts to display</template>
    </empty-state>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="fetchMoreAccounts" />
    <ez-confirmation-modal ref="makeDefault" icon="question">
      <template #title>Make Default Organization?</template>
      <template #content>
        <p>
          When you log into
          {{ selectedOrganization?.platform === 'orderez' ? 'OrderEZ' : 'Foodrazor' }}
          this is the organization you will see by default.
        </p>
      </template>
      <template #footer>
        <ez-button @click="closeMakeDefaultModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmUpdateDefault">Make Default</ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-confirmation-modal ref="leaveOrganizationModal" icon="question" type="red">
      <template #title>Leave Organization?</template>
      <template #content>
        <p v-if="!selectedOrganization?.canLeaveWithoutNewAdmin && newAdminUsers.length > 1">
          You need to select another user as admin in order to be able to leave this organization.
        </p>
        <p v-else-if="!selectedOrganization?.canLeaveWithoutNewAdmin && newAdminUsers.length === 1">
          You need to select another user as admin or downgrade your account in order to be able to
          leave this organization.
        </p>
        <p v-else>You will permanently leave this organization.</p>
        <ez-select
          v-if="!selectedOrganization?.canLeaveWithoutNewAdmin && newAdminUsers.length"
          ref="newAdminSelect"
          name="newAdminId"
          label="Admin"
          :disabled="newAdminUsers.length === 1"
          :options="newAdminUsers"
          @change="onNewAdminChange"
          isFullWidth
        />
      </template>
      <template #footer>
        <ez-button @click="closeLeaveOrganizationModal" type="link">Cancel</ez-button>
        <ez-button
          @click="confirmLeavingOrganization"
          type="red"
          :disabled="!newAdminId && !selectedOrganization?.canLeaveWithoutNewAdmin"
        >
          {{ selectedOrganization?.canLeaveWithoutNewAdmin ? 'Leave' : 'Save Changes' }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-loader :show="isLoading || isSwitchingAccount" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import httpService from '@/api/http';
import { resetState } from '@/store';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzTable from '@/components/ui/Table';
import VEntityInfo from '@/components/v3/elements/VEntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input';
import VBadge from '@/components/v3/elements/VBadge';
import EmptyState from '@/views/common/empty-state';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { EzConfirmationModal } from '@/components/ui/Modal';
import { LOADING_KEY } from '@/util/constants';
import EzSelect from '@/components/ui/Select';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import { debounce } from '@/util/utils';

export default {
  components: {
    EzSimpleDropdown,
    EzButton,
    EzTable,
    VEntityInfo,
    EzPremiumBadge,
    StatusBadge,
    EzButtonDropdown,
    EzFilterList,
    EzInput,
    VBadge,
    EmptyState,
    EzConfirmationModal,
    EzLoader,
    EzSelect,
    EzLoadMore,
    EzSpinner,
  },
  data() {
    return {
      accounts: [],
      meta: {},
      selectedOrganization: null,
      filters: {
        term: null,
      },
      isSwitchingAccount: false,
      newAdminUsers: [],
      newAdminId: null,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    isTableDataLoading() {
      return (
        this.getLoading(LOADING_KEY.UPDATE_DEFAULT_ACCOUNT) ||
        this.getLoading(LOADING_KEY.LEAVE_ACCOUNT)
      );
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ACCOUNTS) || this.getLoading('fetch-login-token');
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ACCOUNTS);
    },
  },
  methods: {
    ...mapActions('entities/users', ['fetchAccounts', 'updateDefaultAccount', 'leaveAccount']),
    async fetchPremiumAccounts(filters = {}, loadingKey = LOADING_KEY.FETCH_ACCOUNTS) {
      const { data } = await this.fetchAccounts({
        accountType: 'premium',
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        ...filters,
        loadingKey,
      });
      if (loadingKey === LOADING_KEY.FETCH_MORE_ACCOUNTS) {
        this.accounts = [...this.accounts, ...data.data];
      } else {
        this.accounts = data.data;
      }
      this.meta = data.meta;
    },
    async fetchMoreAccounts() {
      await this.fetchPremiumAccounts(this.filters, LOADING_KEY.FETCH_MORE_ACCOUNTS);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.meta = {};
      await this.fetchPremiumAccounts();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        this.filters = {
          ...this.filters,
          term: event,
        };
      } else {
        this.filters = {
          ...this.filters,
          [filterName]: event.id,
        };
      }
      this.meta = {};
      await this.fetchPremiumAccounts(this.filters);
    }, 300),
    onNewAdminChange(option) {
      this.newAdminId = option.id;
    },
    makeDefault(row) {
      this.$refs.makeDefault.open();
      this.selectedOrganization = row;
    },
    async leaveOrganization(row) {
      this.selectedOrganization = row;
      const { data } = await httpService.get(`/accounts/${row.id}/users`);
      this.newAdminUsers = data.data.filter(item => item.id !== row.id);
      if (this.newAdminUsers.length) {
        this.newAdminUsers = [{ id: null, name: 'Select User' }, ...this.newAdminUsers];
      } else {
        this.newAdminUsers = [{ id: null, name: 'No Users' }];
      }
      this.$refs.leaveOrganizationModal.open();
    },
    closeMakeDefaultModal() {
      this.selectedOrganization = null;
      this.$refs.makeDefault.close();
    },
    closeLeaveOrganizationModal() {
      this.selectedOrganization = null;
      this.newAdminId = null;
      this.$refs.leaveOrganizationModal.close();
    },
    async confirmUpdateDefault() {
      await this.updateDefaultAccount({ accountId: this.selectedOrganization.id });
      this.meta = {};
      await this.fetchPremiumAccounts();
      this.selectedOrganization = null;
      this.$refs.makeDefault.close();
    },
    async confirmLeavingOrganization() {
      await this.leaveAccount({
        accountId: this.selectedOrganization.id,
        ...(this.newAdminId ? { newAdminId: this.newAdminId } : {}),
      });
      this.meta = {};
      await this.fetchPremiumAccounts();
      this.selectedOrganization = null;
      this.$refs.leaveOrganizationModal.close();
    },
    async switchAccount(row) {
      this.isSwitchingAccount = true;
      const { data } = await httpService.put(`/accounts/switch/${row.id}`);
      const { platform } = data.data;
      if (process.env.VUE_APP_PLATFORM_TITLE.toLowerCase() === platform) {
        await httpService.delete('/manager/oauth');
        localStorage.clear();
        resetState();
      }
      this.isSwitchingAccount = false;

      return data;
    },
    async redirectToOtherAccount(row) {
      if (row.id !== this.loggedUser.id) {
        const data = await this.switchAccount(row);
        window.location.href = `${data.data.url}/login?token=${data.data.token}`;
      } else {
        this.$router.push({ name: 'platform-home' });
      }
    },
    async goToUserManagement(row) {
      if (row.id !== this.loggedUser.id) {
        const data = await this.switchAccount(row);
        if (row.scope === 'distributor') {
          window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=${row.scope}-settings-users`;
        } else {
          window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=${row.scope}-group-settings-users`;
        }
      } else if (row.scope === 'distributor') {
        this.$router.push({ name: `${row.scope}-settings-users` });
      } else {
        this.$router.push({ name: `${row.scope}-group-settings-users` });
      }
    },
    async goToSettings(row) {
      if (row.id !== this.loggedUser.id) {
        const data = await this.switchAccount(row);
        if (row.scope === 'distributor') {
          window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=${row.scope}-settings`;
        } else {
          window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=${row.scope}-group-settings`;
        }
      } else if (row.scope === 'distributor') {
        this.$router.push({ name: `${row.scope}-settings` });
      } else {
        this.$router.push({ name: `${row.scope}-group-settings` });
      }
    },
  },
  async created() {
    const { data } = await this.fetchAccounts();
    await this.fetchPremiumAccounts();
    this.$emit('numberOfPremiumAccounts', this.meta.totalCount);
    if (data.meta.totalCount > this.meta.totalCount) {
      this.$emit('numberOfFreemiumAccounts', data.meta.totalCount - this.meta.totalCount);
    }
  },
};
</script>

<style lang="scss" scoped>
:deep() .map {
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ez-simple-dropdown__label {
      letter-spacing: 0.25px;
    }

    .ez-simple-dropdown__dropdown {
      min-width: 100%;

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

.create-new-account {
  width: auto;
  &__btn {
    display: block;
  }

  :deep() .ez-simple-dropdown {
    &__label,
    &__display-container svg {
      color: $color-white;
    }

    &__label {
      font-weight: 400;
      margin-right: 16px;
    }

    &__display-container {
      height: 36px;
      background-color: $color-primary-blue;

      &--active {
        background-color: $color-primary-dark-blue;
      }
    }

    &__dropdown {
      left: auto;
      right: 0;
      width: max-content;
    }
  }
  .button--link {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    text-align: left;
    font-weight: 400;

    &:hover {
      background: $color-gray-F5;
    }

    &:disabled {
      color: $color-gray-6C;
      background-color: transparent;
    }
  }
  svg {
    margin-right: 0.5rem;
  }
  hr {
    margin: 0.25rem 0.75rem;
    border-style: solid;
  }
}

:deep() .table .actions-cell {
  overflow: visible;
  .button-dropdown--secondary,
  .button-dropdown__toggle-container .button--secondary {
    background-color: transparent;
  }
  .button-dropdown__toggle-container .button {
    min-width: auto;
  }

  .button--secondary {
    padding: 0;
  }

  .button-dropdown__dropdown span.label {
    display: block;
    text-transform: uppercase;
    text-align: left;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: $color-gray-6C;
    padding: 12px 12px 4px 12px;
  }
  .button-dropdown__dropdown .button {
    color: $color-gray-25;
  }
  .button-dropdown__dropdown .button.red {
    color: $color-primary-red;
    &:disabled {
      background-color: transparent;
    }
  }
}

:deep() .table .e-badge,
:deep() .table .status {
  overflow: initial;
}
</style>
