<template>
  <div class="tiers">
    <div class="tiers__item">
      <h4>Tier Pricing</h4>
      <p class="text-color--gray">
        Tier pricing gives customers discounts when they order certain amount of the products.
      </p>
      <tiers
        :productId="productId"
        :distributorId="isAdmin ? distributorId : loggedUser.distributor.id"
        :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.TIER_PRICING.TIERS"
      />
    </div>

    <div class="tiers__item">
      <h4>Special Tier Pricing</h4>
      <!-- eslint-disable-next-line -->
      <p class="text-color--gray">
        Special tier pricing gives selected customers special discounts when ordering certain
        amounts of products
      </p>
      <special-tiers
        :product="product"
        :distributorId="isAdmin ? distributorId : loggedUser.distributor.id"
        :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.TIER_PRICING.SPECIAL_TIERS"
        @success="onSpecialTierSuccess"
        @deleteSuccess="onSpecialTierSuccess"
      />
    </div>

    <div class="tiers__item">
      <h4>Customer Category Pricing</h4>
      <!-- eslint-disable-next-line -->
      <p class="text-color--gray">
        Customer Category pricing gives selected customers a specific price
      </p>
      <div class="warehouses">
        <div
          v-for="(cat, idx) in selectedCustomerCategoryPricings"
          :key="idx"
          class="warehouses__warehouse"
        >
          <ez-button
            type="link"
            class="warehouses__warehouse__remove"
            @click="removeCategoryPricing(cat.id)"
          >
            <font-awesome-icon icon="times" /> Remove
          </ez-button>

          <div class="warehouses__warehouse__existing" v-if="cat.isOld">
            <span class="warehouses__warehouse__existing__label">Customer Category</span>
            <span class="warehouses__warehouse__existing__name">
              {{ findSelected(cat.id).name }}
            </span>
          </div>

          <v-select-search
            v-else-if="customerCategories.length"
            label="Customer Category"
            placeholder="Select Customer Category"
            class="warehouses__warehouse__select"
            :data="customerCategories"
            :selected="findSelected(cat.id)"
            :transformer="transformer"
            @selected="obj => selectCategory(obj, idx)"
            is-full-width
          />
          <ez-mask-input
            form-key=""
            type="input"
            name="customerCategoryPricings"
            label="Price"
            class="warehouses__warehouse__par-level mt-16"
            :value="cat.price"
            :allow-negative-value="false"
            @input="updateCategoryPricing(cat.id, $event)"
          />
        </div>
        <ez-button
          type="secondary"
          class="add-par-level my-24"
          :disabled="addCustomerCategoryPricingDisabled"
          @click="addCustomerCategoryPricing"
          isFullWidth
        >
          <font-awesome-icon icon="plus" />
          Add Customer Category Price
        </ez-button>
      </div>
      <ez-button
        :disabled="saveCategoryPricingDisabled"
        @click="updateProduct"
        :isLoading="isSaving"
      >
        Save Changes
      </ez-button>
    </div>

    <div class="tiers__item">
      <h4>Trade Deal</h4>
      <!-- eslint-disable-next-line -->
      <p class="text-color--gray">
        Trade Deal lets you add a minimum amount of products to give customers an additional item
        for a specific price
      </p>
      <trade-deals
        :productId="productId"
        :distributorId="isAdmin ? distributorId : loggedUser.distributor.id"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { isAdmin, LOADING_KEY } from '@/util/constants';
import Tiers from '@/views/common/products/tiers';
import SpecialTiers from '@/views/common/products/special-tiers';
import EzButton from '@/components/ui/Button';
import EzMaskInput from '@/components/ui/MaskInput';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import uuid from 'uuid/v4';
import httpService from '@/api/http';
import flash from '@/components/ui/FlashMessage';
import TradeDeals from '@/views/common/products/trade-deals';

/**
 * TierPricing
 * @version 1.0.0
 * @since 2.0.0
 */

export default {
  components: {
    Tiers,
    SpecialTiers,
    EzButton,
    EzMaskInput,
    VSelectSearch,
    TradeDeals,
  },
  data() {
    return {
      isAdmin: isAdmin(),
      distributorId: Number(this.$route.params.id),
      productId: Number(isAdmin() ? this.$route.params.productId : this.$route.params.id),
      supplierCy,
      product: {},
      customerCategories: [],
      selectedCustomerCategoryPricings: [],
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    addCustomerCategoryPricingDisabled() {
      return this.customerCategories.length === this.selectedCustomerCategoryPricings.length;
    },
    saveCategoryPricingDisabled() {
      return (
        this.product.customerCategoryPricings?.length === 0 &&
        this.selectedCustomerCategoryPricings?.length === 0
      );
    },
    isSaving() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT);
    },
  },
  methods: {
    ...mapActions('entities/products', ['distributorFetchProduct']),
    ...mapActions('entities/distributors', ['distributorFetchCustomerCategories']),
    async fetchProduct() {
      const { data } = await this.distributorFetchProduct({ id: this.productId });
      this.product = data.data;
      this.selectedCustomerCategoryPricings = this.product.customerCategoryPricings.map(item => ({
        id: item.customerCategory.id,
        price: item.value,
      }));
    },
    async onSpecialTierSuccess() {
      await this.fetchProduct();
    },
    addCustomerCategoryPricing() {
      const cat = this.customerCategories.filter(
        ({ id }) => !this.selectedCustomerCategoryPricings.map(w => w.id).includes(id),
      );
      this.selectedCustomerCategoryPricings = [
        ...this.selectedCustomerCategoryPricings,
        {
          id: cat[0].id,
          price: 0,
          isOld: false,
        },
      ];
    },
    selectCategory(selected, idx) {
      let updated = { id: `empty-${uuid()}`, price: 0 };

      if (!selected.reset) {
        updated = {
          ...this.selectedCustomerCategoryPricings[idx],
          id: selected.id,
          name: selected.name,
        };
      }

      this.selectedCustomerCategoryPricings.splice(idx, 1, updated);
    },
    removeCategoryPricing(id) {
      const idx = this.selectedCustomerCategoryPricings.findIndex(w => w.id === id);
      this.selectedCustomerCategoryPricings.splice(idx, 1);
    },
    updateCategoryPricing(id, val) {
      const idx = this.selectedCustomerCategoryPricings.findIndex(w => w.id === id);
      this.selectedCustomerCategoryPricings[idx].price = val;
    },
    async updateProduct() {
      const method = this.isAdmin
        ? `/admin/distributors/${this.distributorId}/products/${this.product.id}`
        : `/distributor/products/${this.product.id}`;
      const data = new FormData();
      if (this.selectedCustomerCategoryPricings.length) {
        this.selectedCustomerCategoryPricings.forEach((cat, i) => {
          data.append(`customerCategoryPricings[${i}][value]`, cat.price);
          data.append(`customerCategoryPricings[${i}][customerCategoryId]`, cat.id);
        });
      } else data.append('customerCategoryPricings', '');
      try {
        await httpService.patch(method, data, {
          headers: {
            loadingKey: LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
          },
        });
        await this.fetchProduct();
        flash.success({ title: 'Customer Category Pricing successfully updated!' });
      } catch (e) {
        flash.error({ title: 'Something went wrong!' });
      }
    },
    findSelected(id) {
      return this.customerCategories.find(w => w.id === id);
    },
    transformer(data) {
      return data.filter(
        ({ id }) => !this.selectedCustomerCategoryPricings.map(w => w.id).includes(id),
      );
    },
  },
  async created() {
    await this.fetchProduct();
    const { data } = await this.distributorFetchCustomerCategories();
    this.customerCategories = data.data;
  },
};
</script>

<style lang="scss" scoped>
.tiers {
  &__item {
    &:not(:first-child) {
      @include separator('top', 2rem);
    }
    h4 {
      @include font-size(14px, 16px);
      font-weight: 500;
      margin: 0 0 0.5rem;
    }

    p {
      @include font-size(14px, 22px);
      margin: 0 0 1rem 0;
      color: $color-gray-6C;
    }
  }
}

.warehouses {
  &__warehouse {
    position: relative;
    padding-top: 24px;

    &:not(:first-child) {
      margin-top: 24px;
      border-top: 1px dashed #dee1e4;
    }

    &__existing {
      &__label {
        display: block;
        @include font-size(12px, 18px);
        font-weight: 600;
        color: $color-gray-6C;
      }

      &__name {
        display: block;
        @include font-size(14px, 20px);
        font-weight: 500;
      }
    }

    &__select {
      :deep() .select-search {
        &__trigger span {
          @include font-size(14px, 20px);

          &:not(.select-search__value) {
            color: $color-gray-6C;
          }
        }

        &__search-wrapper {
          display: none;
        }

        &__item {
          @include font-size(14px, 20px);
        }
      }
    }

    &__par-level {
      :deep() .mask-input__input {
        font-weight: normal;
      }
    }

    &__remove {
      @include font-size(12px, 18px);
      position: absolute;
      top: 24px;
      right: 0;
      height: auto;
    }
  }

  .add-par-level {
    &:disabled {
      background-color: $color-gray-F5;
      color: transparentize($color-gray-6C, 0.6);
    }
  }
}
</style>
