<template>
  <div class="special-tier-preview">
    <ul class="special-tier-preview__venues">
      <li
        v-for="venue in specialTier.connectedVenues"
        :key="venue.id">
        <ez-entity-info
          :imgUrl="venue.logo"
          imgBorderRadius="50%"></ez-entity-info>
        <div class="special-tier-preview__venues-tooltip">{{ venue.name }}</div>
      </li>
    </ul>
    <tier-preview :tiers="specialTier.tiers" :currency="currency" :data-cy="dataCy" />
  </div>
</template>

<script>
import EzEntityInfo from '@/components/ui/EntityInfo';
import TierPreview from '@/views/common/products/tiers/Preview.vue';

export default {
  components: {
    EzEntityInfo,
    TierPreview,
  },
  props: {
    specialTier: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped lang="scss">
.special-tier-preview {
  &__venues {
    @extend %ul-reset;
    @extend %flex-center;
    margin-bottom: 1rem;

    li {
      position: relative;
      + li {
        margin-left: .375rem;
      }
      &:hover {
        cursor: pointer;
        .special-tier-preview__venues-tooltip {
          display: block;
        }
      }
    }
  }
  &__venues-tooltip {
    @include font-size(12px, 14px);
    font-weight: 400;
    color: $color-white;
    background-color: $color-gray-25;
    padding: .1875rem .357rem;
    border-radius: 2px;
    top: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    position: absolute;
    display: none;
  }
}
</style>
