<template>
  <div>
    <paper v-for="specialTier in product.specialTierPricings" :key="specialTier.id">
      <template #title>
        <span
          :data-cy="hasDataCy ? `${dataCyAttr('TEXT__SPECIAL_TIER_NAME')}-${specialTier.id}` : ''"
        >
          {{ specialTier.name }}
        </span>
      </template>
      <template #actions>
        <ez-button-dropdown
          v-if="canEditProduct"
          class="btn-dropdown"
          buttonType="secondary"
          :data-cy="hasDataCy ? `${dataCyAttr('BUTTON__OPTIONS')}-${specialTier.id}` : ''"
        >
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button
              @click="editSpecialTier(specialTier.id)"
              type="link"
              :data-cy="hasDataCy ? `${dataCyAttr('BUTTON__EDIT')}-${specialTier.id}` : ''"
            >
              Edit
            </ez-button>
            <ez-button
              @click="deleteSpecialTier(specialTier.id)"
              type="red-link"
              :data-cy="hasDataCy ? `${dataCyAttr('BUTTON__DELETE')}-${specialTier.id}` : ''"
            >
              Delete
            </ez-button>
          </template>
        </ez-button-dropdown>
      </template>

      <tier-preview
        :specialTier="specialTier"
        :currency="product && product.currency"
        :data-cy="dataCyAttr('SPECIAL_TIER_PREVIEW')"
      />
    </paper>
    <ez-button
      :disabled="!canEditProduct"
      :title="addSpecialTierDisabledTooltip"
      type="secondary"
      formType="button"
      customClass="add-tier"
      isFullWidth
      @click="openSpecialTierModal"
      :data-cy="dataCyAttr('BUTTON__ADD')"
    >
      <font-awesome-icon icon="plus" />
      <span>Add Special Tier Pricing</span>
    </ez-button>
    <special-tiers-modal
      ref="modal"
      :key="tierKey"
      :product="product"
      :specialTierId="specialTierId"
      :distributorId="distributorId"
      @close="onSpecialTierModalClose"
      @success="onSpecialTierSuccess"
      :data-cy="dataCyAttr('SPECIAL_TIERS_MODAL')"
    />
    <ez-confirmation-modal ref="deleteModal" type="red" @close="onCloseDeleteConfirmation">
      <template #title>Delete this Tier?</template>
      <template #content>
        <p>When deleted, tier price will no longer be applied to this product.</p>
      </template>
      <template #footer>
        <ez-button
          @click="closeDeleteConfirmation"
          type="link"
          :data-cy="dataCyAttr('BUTTON__DELETE_CANCEL')"
        >
          Cancel
        </ez-button>
        <ez-button
          @click="confirmDelete"
          type="red"
          :data-cy="dataCyAttr('BUTTON__DELETE_CONFIRM')"
        >
          Yes, Delete
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapActions } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import { isAdmin } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import TierPreview from './Preview.vue';
import SpecialTiersModal from './Modal.vue';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    EzConfirmationModal,
    SpecialTiersModal,
    TierPreview,
    Paper,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    distributorId: {
      type: Number,
      default: null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      specialTierId: null,
      tierKey: uuid(),
    };
  },
  computed: {
    canEditProduct() {
      return this.$permission.has('editProductPricing');
    },
    addSpecialTierDisabledTooltip() {
      // eslint-disable-next-line no-nested-ternary
      return !this.canEditProduct ? "You don't have permission to add a special tier." : '';
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    openSpecialTierModal() {
      this.$refs.modal.open();
    },
    onSpecialTierModalClose() {
      this.tierKey = uuid();
      this.specialTierId = null;
    },
    openDeleteConfirmation() {
      this.$refs.deleteModal.open();
    },
    closeDeleteConfirmation() {
      this.$refs.deleteModal.close();
    },
    onCloseDeleteConfirmation() {
      this.specialTierId = null;
    },
    deleteSpecialTier(specialTierId) {
      this.specialTierId = specialTierId;
      this.openDeleteConfirmation();
    },
    async confirmDelete() {
      const { distributorId, specialTierId } = this;
      const { id, specialTierPricings } = this.product;

      try {
        await (isAdmin()
          ? this.deleteAdminSpecialTierPricing({
              productId: id,
              distributorId,
              specialTierId,
              specialTierPricings,
            })
          : this.deleteDistributorSpecialTierPricing({
              specialTierId,
              productId: id,
              specialTierPricings,
            }));
        this.$emit('deleteSuccess', specialTierId);
        this.closeDeleteConfirmation();
        flash.success({ title: 'Successfully deleted a special tier.' });
      } catch (e) {
        this.closeDeleteConfirmation();
        console.error(e);
      }
    },
    editSpecialTier(specialTierId) {
      this.specialTierId = specialTierId;
      this.openSpecialTierModal();
    },
    onSpecialTierSuccess(data) {
      this.$emit('success', data);
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
    ...mapActions('entities/products', [
      'deleteDistributorSpecialTierPricing',
      'deleteAdminSpecialTierPricing',
    ]),
    ...mapActions('entities/distributors', ['fetchSingleDistributor']),
  },
  mounted() {
    if (isAdmin()) {
      this.fetchSingleDistributor(this.distributorId);
    }
  },
};
</script>

<style scoped lang="scss">
:deep() .btn-dropdown {
  .drp-btn {
    display: flex;
    justify-content: flex-end;
  }
}

:deep() .overview-paper {
  max-width: 100%;
}

:deep() .button-dropdown {
  justify-content: center;
  background: none;

  &:hover {
    background: none;
  }

  &__toggle-container {
    justify-content: stretch;
    align-items: stretch;
    background: none;

    .button {
      @extend %flex-center;
      background: none;
      flex: 1 1 auto;
      padding: 0;
      transition: none;

      &:hover {
        color: $color-gray-25;
        background: none;
      }

      &:active {
        background: none;
      }
    }
  }
}

.add-tier {
  margin-top: 0.75rem;
}
</style>
