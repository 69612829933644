<template>
  <div class="ez-tier-list">
    <div class="ez-tier-list__discount-type">
      <ez-radio-button
        value="price"
        :label="`Set Price ${currencySymbol}`"
        :data="tierPricing.discountType"
        :form-key="formKey"
        :name="`${name}[discountType]`"
        @change="updateDiscountType"
        :data-cy="dataCyAttr('LABEL__SET_PRICE')"
      />
      <ez-radio-button
        v-if="!toHidePercentageOption"
        value="percentage"
        label="Set Discount (%)"
        :data="tierPricing.discountType"
        :form-key="formKey"
        :name="`${name}[discountType]`"
        @change="updateDiscountType"
        :data-cy="dataCyAttr('LABEL__SET_DISCOUNT')"
      />
    </div>
    <div v-if="tiers && tiers.length" class="ez-tier-list__items">
      <tier-item
        v-for="(tier, i) in tiers"
        :key="tier.id"
        :tier="tier"
        :title="`Tier ${i + 1}`"
        :discount-type="tierPricing.discountType"
        :form-key="formKey"
        :name="`${name}[tiers][${i}]`"
        :currency="currency"
        :ordering-unit="orderingUnit"
        :price-unit="priceUnit"
        :data-cy="dataCyAttr('TIER_ITEM')"
      />
    </div>
    <div
      v-if="hasErrorMessage"
      class="ez-tier-list__error">{{ error.message }}</div>
    <ez-button
      type="secondary"
      formType="button"
      isFullWidth
      @click="addNewTier"
      :data-cy="dataCyAttr('BUTTON__ADD_TIER')"
    >
      <font-awesome-icon icon="plus"/>
      <span>{{ addTierCta }}</span>
    </ez-button>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapGetters, mapMutations } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzRadioButton from '@/components/ui/RadioButton';
import { clone, getCurrency } from '@/util/utils';
import TierItem from './TierItem.vue';

export default {
  components: {
    EzButton,
    EzRadioButton,
    TierItem,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tierPricing: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    orderingUnit: {
      required: false,
      type: Object,
      default: () => null,
    },
    priceUnit: {
      required: false,
      type: Object,
      default: () => null,
    },
    isMarketPrice: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  provide() {
    return {
      removeTier: this.removeTier,
    };
  },
  data() {
    return {
      tiers: [],
    };
  },
  computed: {
    ...mapGetters('errors', ['getError']),
    currencySymbol() {
      const { symbol } = this.currency || getCurrency() || {};
      return symbol ? `(${symbol})` : '';
    },
    addTierCta() {
      return this.tiers.length ? 'Add Another Tier' : 'Add Tier';
    },
    hasErrorMessage() {
      return this.error
        && typeof this.error === 'object'
        && Object.prototype.hasOwnProperty.call(this.error, 'message');
    },
    error() {
      return this.getError(this.formKey, `${this.name}[tiers]`);
    },
    toHidePercentageOption() {
      return this.isMarketPrice;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    addNewTier() {
      this.CLEAR_ERRORS({ key: this.formKey });

      this.tiers.push({
        id: uuid(),
      });
    },
    removeTier(tier) {
      this.CLEAR_ERRORS({ key: this.formKey });
      this.tiers = this.tiers.filter(t => t.id !== tier.id);
    },
    updateDiscountType(discountType) {
      this.$emit('change', { discountType });
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
  created() {
    this.tiers = clone(this.tierPricing.tiers || []);

    if (!this.tiers.length) {
      this.addNewTier();
    }
  },
};
</script>

<style scoped lang="scss">
.ez-tier-list {
  &__error {
    margin-top: 0.5rem;
    color: $color-primary-red;
    @include font-size(12px, 14px);
  }
  &__discount-type {
    display: flex;
    > * + * {
      margin-left: 0.75rem;
    }
  }
  &__items {
    margin-top: 1.5rem;

    :deep() .ez-tier-item:not(:last-child) {
      border-bottom: 1px dashed #e1e5ed;
      padding-bottom: 16px;
    }
  }
  .button {
    margin-top: 16px;
  }
}
</style>
