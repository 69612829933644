<template>
  <distributor-form
    ref="form"
    :formKey="formKey"
    :action="action"
    :avatarColumnMode="true"
    :distributorId="distributorId"
    :method="method"
    class="distributor-info"
    @submitSuccess="onSubmitSuccess"
    @formValid="isFormValid"
    hide-notification-settings
  />
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import DistributorForm from '@/views/admin/distributors/Form.vue';

export default {
  mixins: [wizardListenerMixin],
  components: {
    DistributorForm,
  },
  data() {
    return {
      formKey: 'new-distributor',
    };
  },
  computed: {
    isExistingDistributor() {
      return this.draft && this.draft.id;
    },
    distributorId() {
      return this.isExistingDistributor ? this.draft.id : -1;
    },
    method() {
      return this.isExistingDistributor ? 'patch' : 'post';
    },
    action() {
      if (!this.isExistingDistributor) {
        return '/distributors';
      }

      return `/distributors/${this.draft.id}`;
    },
    ...mapState('entities/distributors', ['draft']),
  },
  methods: {
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapMutations('entities/distributors', ['UPDATE_DRAFT']),
    onSubmitSuccess({ data }) {
      this.UPDATE_DRAFT(data.data);
      this.$emit('stepCompleted');
    },
    isFormValid(valid) {
      if (valid) this.enableNextStep();
      else this.disableNextStep();
    },
    onNextStep() {
      this.$refs.form.submitForm();
    },
  },
  mounted() {
    this.fetchDefaultImages();
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
.distributor-form {
  :deep() .ez-image-upload {
    margin-bottom: 1.5rem;
  }
}
</style>
