<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import VTagEditor from '@/components/v3/elements/VTagEditor';
import { LOADING_KEY } from '@/util/constants';

/**
 * CreateTagsModal
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'CreateTagModal',
  components: {
    EzFormModal,
    EzButton,
    VTagEditor,
  },
  props: {
    tagType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      createdTag: {},
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapState('tags', ['tagColors']),
    disabled() {
      return !this.createdTag.name;
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_CREATE_TAG);
    },
  },
  methods: {
    ...mapActions('tags', ['createTag']),
    open() {
      this.$refs.modal.open();
      this.isOpen = true;
    },
    close() {
      this.$refs.tagEditor.clearErrors();
      if (this.isOpen) {
        this.$refs.modal.close();
      }
    },
    onClose() {
      this.$refs.tagEditor.clearErrors();
      if (this.isOpen) {
        this.createdTag = {};
        this.close();
        this.$emit('onClose');
      }
    },
    async submit() {
      try {
        const { data } = await this.createTag({
          name: this.createdTag.name,
          colorId: this.createdTag.color.id,
          type: this.tagType,
          formKey: 'createTag',
        });
        this.$emit('confirm', data.data);
      } finally {
        this.createdTag = {};
      }
    },
    onTagChange(tag) {
      this.createdTag = tag;
    },
  },
};
</script>

<template>
  <ez-form-modal class="tags-modal" ref="modal" @close="onClose">
    <template #title>Create tag</template>
    <template #content>
      <v-tag-editor
        formKey="createTag"
        :value="createdTag"
        :colors="tagColors"
        @change="onTagChange"
        ref="tagEditor"
      />
    </template>
    <template #footer>
      <ez-button type="link" formType="button" @click="onClose">
        Cancel
      </ez-button>
      <ez-button :isLoading="isLoading" :disabled="disabled" @click="submit" type="primary">
        Save Tag
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.tags-modal {
  @include z-index('modal', 10);

  .name-input {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .input {
      width: 100%;
    }
  }

  .color-indicator {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-right: 12px;
  }

  .color-selection__display {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #e1e5ed;
    border-radius: 1px;

    div {
      cursor: pointer;
      padding-bottom: 12px;

      .color-indicator {
        margin-right: 0;
      }
    }

    .color-indicator--selected {
      border-bottom: 1px solid #4D7CFE;
      border-radius: 1px;
    }
  }

  :deep() {
    .modal {
      @include z-index('modal', 20);

      .modal__inner {
        .modal__text {
          overflow-y: inherit;
        }
      }
    }
  }
}
</style>
