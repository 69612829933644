<template>
  <div>
    <ez-user-list
      :role="userRole"
      :users="[{
        ...this.loggedUser,
        role: {
          ...this.loggedUser.role,
          name: 'Admin',
        },
        venue: { name: 'All' }
      }]"
      :columns="['name', 'email', 'role', 'venue']"
      :columnProps="{
        venue: { class: 'venue-cell' },
        role: { class: 'role-cell' },
      }"
      :headers="{
        accountName: () => 'Venue',
        venue: () => $t('global.venue').toLowerCase(),
      }"
      :isEditable="false"
      :canAddNewUser="false"
    >
    </ez-user-list>
    <div class="has-admin-note">
      <font-awesome-icon icon="info-circle" />
      <span>Note: You will be able to add more users later within the account.</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzUserList from '@/views/common/users';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
  },
  computed: {
    userRole() {
      return localStorage.getItem('role');
    },
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('entities/groups', ['draft']),
  },
  methods: {
    ...mapMutations('entities/groups', ['UPDATE_DRAFT']),
    onNextStep() {
      this.UPDATE_DRAFT({ users: [this.loggedUser] });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
  },
  async mounted() {
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
.user-permissions {
  @include separator();
  .ez-dropdown + .ez-dropdown {
    margin-top: 0.75rem;
  }
  .ez-checkbox {
    margin: 1rem 0;
  }
}
.has-admin-note {
  @extend %flex-center;
  justify-content: center;
  margin-top: px-to-rem(16px);
  color: $color-gray-6C;
  svg {
    color: #b9bfcd;
    margin-right: px-to-rem(6px);
  }
  span {
    @include font-size(14px);
    font-weight: 400;
  }
}
</style>
