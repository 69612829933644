<template>
  <div class="map__wrapper">
    <div class="map__header">
      <router-link :to="{ name: 'platform-home' }" class="map__header-nav">
        <font-awesome-icon icon="angle-left" class="mr-8" />
        <span>Back</span>
      </router-link>
      <div class="map__header-title">
        <h1>Accounts</h1>
        <p>Create, manage and sign in to your accounts.</p>
      </div>
    </div>
    <ez-nav class="map__navigation">
      <template #nav>
        <ul>
          <li v-if="premiumAccountsLength">
            <router-link :to="{ name: 'manage-accounts-premium' }">
              Premium
            </router-link>
          </li>
          <li v-if="freemiumAccountsLenght">
            <router-link :to="{ name: 'manage-accounts-freemium' }">
              Freemium
            </router-link>
          </li>
        </ul>
      </template>
      <router-view
        @numberOfFreemiumAccounts="updateNumberOfFreemiumAccounts"
        @numberOfPremiumAccounts="updateNumberOfPremiumAccounts"
      />
    </ez-nav>
  </div>
</template>

<script>
import EzNav from '@/components/ui/Nav';

export default {
  components: {
    EzNav,
  },
  data() {
    return {
      premiumAccountsLength: null,
      freemiumAccountsLenght: null,
    };
  },
  methods: {
    updateNumberOfFreemiumAccounts(val) {
      this.freemiumAccountsLenght = val;
    },
    updateNumberOfPremiumAccounts(val) {
      this.premiumAccountsLength = val;
      if (this.premiumAccountsLength === 0) this.$router.push({ name: 'manage-accounts-freemium' });
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  &__wrapper {
    padding: 80px 120px 60px 120px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &::after {
      content: '';
      flex: 1;
    }

    &-nav {
      flex: 1;
      display: flex;
      align-items: center;
      color: $color-gray-6C;
      font-weight: 400;
      font-size: 24px;
      text-decoration: none;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &-title {
      text-align: center;
      h1 {
        margin-bottom: 8px;
        margin-top: 0;
        color: $color-gray-25;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
      }
      p {
        margin: 0;
        color: $color-gray-6C;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  :deep() &__navigation {
    a {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
