<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import CreateTagModal from '@/components/v3/elements/VProductTagsSettings/CreateTagModal.vue';
import EditTagsModal from '@/components/v3/elements/VProductTagsSettings/EditTagsModal.vue';
import VTagSelectSearch from '@/components/v3/elements/VTagSelectSearch';


/**
 * AddTagsModal
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'AddTagsModal',
  components: {
    EzFormModal,
    EzButton,
    CreateTagModal,
    EditTagsModal,
    VTagSelectSearch,
  },
  props: {
    tagType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('tags', [
      'unselectedTags',
      'selectedTags',
    ]),
    ...mapGetters('loading', ['getLoading']),
    disabled() {
      return !this.selectedTags.length;
    },
  },
  methods: {
    ...mapMutations('tags', [
      'ADD_SELECTED_TAG',
      'REMOVE_SELECTED_TAG',
      'CLEAR_SELECTED',
    ]),
    open() {
      this.$refs.addModal.open();
    },
    close() {
      this.$refs.addModal.close();
      this.$refs.tagsSelectSearch.resetSearch();
    },
    onClose() {
      this.$refs.tagsSelectSearch.resetSearch();
    },
    async submit() {
      this.$emit('confirm');
    },
    onSelectedTagsChange({ tag, isUnselect }) {
      if (!isUnselect) {
        this.ADD_SELECTED_TAG({ tag, isNew: false });
      } else {
        this.REMOVE_SELECTED_TAG(tag);
      }
    },
    async openEditModal() {
      this.close();
      this.$refs.editTagsModal.open();
    },
    async openCreateModal() {
      this.close();
      this.$refs.createTagModal.open();
    },
    addTag(tag) {
      this.ADD_SELECTED_TAG({ tag, isNew: true });
      this.$refs.createTagModal.close();
      this.open();
    },
    exitTagCreation() {
      this.open();
    },
    exitTagEdit(deleteFlag) {
      if (!deleteFlag) {
        this.$refs.editTagsModal.close();
        this.open();
      }
    },
    clearSelected() {
      this.CLEAR_SELECTED();
    },
  },
};
</script>

<template>
  <div>
    <ez-form-modal class="tags-modal" ref="addModal" @close="onClose">
      <template #title>Add Tags to {{ tagType === 'product' ? 'Product' : 'Customer' }}</template>
      <template #content>
        <v-tag-select-search
          ref="tagsSelectSearch"
          :selectedTags="selectedTags"
          :unselectedTags="unselectedTags"
          @change="onSelectedTagsChange"
        />
        <ez-button
            type="secondary"
            is-full-width
            @click="openCreateModal"
          >
            <font-awesome-icon icon="plus" />
            Create New Tag
        </ez-button>
        <ez-button
            class="mt-16"
            type="secondary"
            is-full-width
            @click="openEditModal"
          >
            <font-awesome-icon icon="pen" />
            Edit Tags
        </ez-button>
      </template>
      <template #footer>
        <div class="tags-actions">
          <div>
            <ez-button
              v-if="selectedTags.length"
              type="link"
              class="reset-button"
              @click="clearSelected"
            >
              <span>Clear Tags</span>
            </ez-button>
          </div>
          <div>
            <ez-button
              type="link"
              formType="button"
              @click="close"
            >
              Cancel
            </ez-button>
            <ez-button
              @click="submit"
              type="primary"
            >
              Save Tags
            </ez-button>
          </div>
        </div>
      </template>
    </ez-form-modal>
    <create-tag-modal
      ref="createTagModal"
      @confirm="addTag"
      @onClose="exitTagCreation"
      :tagType="tagType"
    />
    <edit-tags-modal
      ref="editTagsModal"
      @onClose="exitTagEdit"
    />
  </div>
</template>

<style lang="scss" scoped>
.tags-modal {
  @include z-index('modal', 10);

  .tags-modal__text {
    @include font-size($label-font-size, 18px);
    color: $color-gray-6C;
    margin-bottom: 18px;
  }

  .all-tags {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed $color-gray-E1;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }

  .tags-actions {
    display: flex;
    justify-content: space-between;
  }

  :deep() {
    .input-group.search {
      display: flex;
      align-items: center;
      margin-bottom: 34px;
      font-weight: 600;

      .input-group__label {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
    .modal {
      @include z-index('modal', 20);

      .modal__inner {
        .modal__text {
          overflow-y: inherit;
        }
      }
    }
  }
}
</style>
