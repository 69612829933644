<template>
  <venue-list
    :groupId="draft.id"
    :venues="venues"
    editInModal
    :create-disabled="disableAddingOutlets"
    @venueCreated="onVenueCreated"
    @loadMore="loadMoreVenues"
    :hasMoreVenues="!!meta.nextId && !!meta.nextValue"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import VenueList from '@/views/common/venues/List.vue';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VenueList,
  },
  data() {
    return {
      venues: [],
      meta: {},
      disableAddingOutlets: false,
    };
  },
  computed: {
    ...mapState('entities/groups', ['draft']),
  },
  methods: {
    ...mapActions('entities/venues', ['fetchVenuesForVenueGroup']),
    async fetchVenuesForGroup() {
      const { data } = await this.fetchVenuesForVenueGroup({ groupId: this.draft.id });
      this.venues = data.data;
      this.meta = data.meta;
    },
    async onVenueCreated() {
      this.meta = {};
      await this.fetchVenuesForGroup();
    },
    async loadMoreVenues() {
      const { data } = await this.fetchVenuesForVenueGroup({
        groupId: this.draft.id,
        loadingKey: 'fetch-more-venues-for-group',
        query: this.meta,
      });
      this.venues = [...this.venues, ...data.data];
      this.meta = data.meta;
    },
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
  },
  async mounted() {
    await this.fetchVenuesForGroup();
  },
  watch: {
    venues(val) {
      if (val.length) {
        this.enableNextStep();
        this.disableAddingOutlets = true;
      } else {
        this.disableNextStep();
        this.disableAddingOutlets = false;
      }
    },
  },
};
</script>

<style scoped></style>
