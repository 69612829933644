<template>
  <ez-wizard
    :fullWidth="currentStep === DISTRIBUTOR_STEP_2"
    @exit="onWizardExit">
    <template #title>Add New {{ $t('global.distributor') }}</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === DISTRIBUTOR_STEP_1,
          'ez-step-completed': currentStep > DISTRIBUTOR_STEP_1,
        }">
          1. {{ $t('global.distributor') }} Info
        </li>
        <li :class="{
          'ez-step-active': currentStep === DISTRIBUTOR_STEP_2,
          'ez-step-completed': currentStep > DISTRIBUTOR_STEP_2,
        }">
          2. Users
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button @click="goToNextStep" :disabled="nextDisabled">
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep === DISTRIBUTOR_STEP_2"
        type="link"
        formType="button"
        @click="goToPreviousStep">
        <font-awesome-icon icon="arrow-left"/>
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>{{ currentStepPageInfo }}</template>
    <component
      :is="currentStepComponent"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"
    />
  </ez-wizard>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard.vue';
import steps, {
  DISTRIBUTOR_STEP_1,
  DISTRIBUTOR_STEP_2,
  DISTRIBUTOR_STEP_3,
} from './steps';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  data() {
    return {
      currentStep: 0,
      DISTRIBUTOR_STEP_1,
      DISTRIBUTOR_STEP_2,
      DISTRIBUTOR_STEP_3,
      nextDisabled: true,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    ...mapState('entities/distributors', ['draft']),
  },
  watch: {
    currentStep(currentStep) {
      this.UPDATE_DRAFT({ currentStep });
    },
  },
  methods: {
    onStepBack(step) {
      if (step !== undefined && step >= DISTRIBUTOR_STEP_1 && step <= DISTRIBUTOR_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= DISTRIBUTOR_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= DISTRIBUTOR_STEP_3) {
        return;
      }

      this.nextDisabled = true;
      this.currentStep += 1;
    },
    onWizardExit() {
      this.CLEAR_DRAFT();
      this.$router.push({ name: 'manage-accounts' });
    },
    ...mapActions('entities/distributors', [
      'fetchDistributor',
    ]),
    ...mapMutations('entities/distributors', [
      'UPDATE_DRAFT',
      'CLEAR_DRAFT',
    ]),
  },
  created() {
    this.currentStep = this.draft.currentStep || DISTRIBUTOR_STEP_1;
  },
  mounted() {
    if (!this.draft || !this.draft.id) {
      return;
    }

    this.fetchDistributor(this.draft.id);
  },
};
</script>

<style scoped lang="scss">
</style>
