<template>
  <div class="ez-tier-item">
    <span class="u-flex-space mb-12">
      <h4 class="ez-tier-item__title m-0">
        {{ title }}
      </h4>
      <ez-button
        class="ez-tier-item__btn"
        type="inline-link"
        formType="button"
        :is-full-width="false"
        @click="removeTier(tier)"
        :data-cy="hasDataCy ? `${dataCyAttr('BUTTON__REMOVE_TIER')}-${tier.id}` : ''"
      >
        <font-awesome-icon icon="times" />
        <span>Remove</span>
      </ez-button>
    </span>
    <ez-mask-input
      class="ez-tier-item__number-container"
      type="input"
      label="From number of products"
      placeholder="Enter Quantity"
      is-form-input
      :form-key="formKey"
      :name="`${name}[minQuantity]`"
      :value="tier.minQuantity"
      :precision="isQuantityUnitFractional ? 2 : 0"
      :price-prefix="false"
      :allow-negative-value="false"
      :data-cy="hasDataCy ? `${dataCyAttr('INPUT__NUMBER_OF_PRODUCTS')}-${tier.id}` : ''"
    >
      <template #suffix>
        {{ formatUnitLabel(orderingUnit) }}
      </template>
    </ez-mask-input>
    <div class="ez-tier-item__price-container">
      <ez-mask-input
        class="ez-tier-item__price"
        type="input"
        :key="discountType"
        is-form-input
        :form-key="formKey"
        :name="`${name}[value]`"
        :value="tier.value || 0"
        :label="labels[discountType]"
        :custom-prefix="getPrefix(discountType)"
        :precision="isDiscountTypePercentage ? 0 : 2"
        :allow-negative-value="false"
        :data-cy="hasDataCy ? `${dataCyAttr('INPUT__VALUE')}-${tier.id}` : ''"
      />
    </div>
    <!-- Non integer (uuid) is used to track new (non submitted) tier items -->
    <input v-if="Number.isInteger(tier.id)" type="hidden" :value="tier.id" :name="`${name}[id]`" />
  </div>
</template>

<script>
import EzButton from '@/components/ui/Button';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import { getCurrency } from '@/util/utils';
import { DiscountType, UNIT_TYPE_FRACTIONAL } from '@/util/constants';

export default {
  components: {
    EzMaskInput,
    EzButton,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tier: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    discountType: {
      type: String,
      default: DiscountType.PRICE,
      validator: val => Object.values(DiscountType).includes(val),
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    orderingUnit: {
      required: false,
      type: Object,
      default: () => null,
    },
    priceUnit: {
      required: false,
      type: Object,
      default: () => null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  inject: ['removeTier'],
  data() {
    return {
      labels: {
        price: 'Price',
        percentage: 'Percentage',
      },
    };
  },
  computed: {
    currencySymbol() {
      const { symbol } = this.currency || getCurrency() || {};
      return symbol;
    },
    isDiscountTypePercentage() {
      return this.discountType === DiscountType.PERCENTAGE;
    },
    quantityUnit() {
      return this.priceUnit || this.orderingUnit;
    },
    isQuantityUnitFractional() {
      return this.quantityUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    getPrefix(discountType) {
      const prefixMap = {};
      prefixMap[DiscountType.PERCENTAGE] = '%';
      prefixMap[[DiscountType.PRICE]] = this.currencySymbol;
      return prefixMap[discountType];
    },
    formatUnitLabel(unitObj) {
      const { label } = unitObj || {};
      return label ? `/ ${label}` : '';
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped lang="scss">
.ez-tier-item {
  display: flex;
  flex-direction: column;

  :deep() .mask-input__input {
    font-weight: normal;
  }

  &__price-container {
      margin-top: 12px;
  }

  &__price {
    :deep() .input-group__prefix {
      border: 0;
      color: $color-gray-25;
      font-weight: normal;
    }

    :deep() .input-group__suffix {
      border: 0;
      color: $color-gray-25;
      font-weight: normal;
    }

    :deep() .input-group__input input {
      padding-left: 1.75rem;
    }
  }

  + .ez-tier-item {
    margin-top: 16px;
  }
}

.ez-tier-item__title {
  @include font-size(12px, 16px);
  font-weight: bold;
  text-transform: uppercase;
  color: $color-gray-6C;
}

.ez-tier-item__btn {
  @include font-size(12px, 14px);
  font-weight: 400;

  span {
    margin-left: 0.25rem;
  }
}
</style>
