<template>
  <div>
    <paper v-if="hasTiers">
      <template #title>Tiers</template>
      <template #actions>
        <ez-button-dropdown
          v-if="canEditProduct"
          class="btn-dropdown"
          buttonType="secondary"
          :data-cy="dataCyAttr('BUTTON__OPTIONS')"
        >
          <template #icon>
            <font-awesome-icon icon="ellipsis-h"/>
          </template>
          <template #dropdown>
            <ez-button
              @click="openTierModal"
              type="link"
              :data-cy="dataCyAttr('BUTTON__EDIT')"
            >
              Edit
            </ez-button>
            <ez-button
              @click="openDeleteConfirmation"
              type="red-link"
              :data-cy="dataCyAttr('BUTTON__DELETE')"
            >
              Delete
            </ez-button>
          </template>
        </ez-button-dropdown>
      </template>

      <tier-preview
        :tiers="tiers"
        :currency="product && product.currency"
        :data-cy="dataCyAttr('TIER_PREVIEW')"
      />
    </paper>
    <ez-button
      v-else
      :disabled="!canEditProduct"
      type="secondary"
      formType="button"
      isFullWidth
      @click="onAddTierClick"
      :data-cy="dataCyAttr('BUTTON__ADD')"
    >
      <font-awesome-icon icon="plus"/>
      <span>Add Tier Pricing</span>
    </ez-button>
    <tier-modal
      ref="tierModal"
      :product="product"
      :distributorId="distributorId"
      :data-cy="dataCyAttr('TIERS_MODAL')"
      @success="onTierSuccess"
    />
    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Delete this Tier?</template>
      <template #content>
        <p>When deleted, tier price will no longer be applied to this product.</p>
      </template>
      <template #footer>
        <ez-button
          @click="closeDeleteConfirmation"
          type="link"
          :data-cy="dataCyAttr('BUTTON__DELETE_CANCEL')"
        >
          Cancel
        </ez-button>
        <ez-button
          @click="confirmDelete"
          type="red"
          :data-cy="dataCyAttr('BUTTON__DELETE_CONFIRM')"
        >
          Yes, Delete
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import { EzConfirmationModal } from '@/components/ui/Modal';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import Product from '@/models/Product';
import { isAdmin } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import TierModal from './Modal.vue';
import TierPreview from './Preview.vue';


export default {
  components: {
    EzButton,
    EzButtonDropdown,
    TierPreview,
    TierModal,
    EzConfirmationModal,
    Paper,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    distributorId: {
      type: Number,
      required: true,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    product() {
      return Product.find(this.productId) || {
        defaultTierPricing: {
          discountType: 'price',
          tiers: [],
        },
      };
    },
    tiers() {
      return this.product.defaultTierPricing.tiers || [];
    },
    hasTiers() {
      return !!this.tiers.length;
    },
    canEditProduct() {
      return this.$permission.has('editProductPricing');
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    openTierModal() {
      this.$refs.tierModal.open();
    },
    onAddTierClick() {
      this.openTierModal();
    },
    openDeleteConfirmation() {
      this.$refs.deleteModal.open();
    },
    closeDeleteConfirmation() {
      this.$refs.deleteModal.close();
    },
    async confirmDelete() {
      const { productId, distributorId } = this;
      await (isAdmin()
        ? this.deleteAdminDefaultTierPricing({
          productId,
          distributorId,
        })
        : this.deleteDistributorDefaultTierPricing(this.productId));
      this.closeDeleteConfirmation();
      flash.success({ title: 'Successfully deleted default tier.' });
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
    ...mapActions('entities/products', [
      'deleteDistributorDefaultTierPricing',
      'deleteAdminDefaultTierPricing',
    ]),
    onTierSuccess(data) {
      this.$emit('success', data);
    },
  },
};
</script>

<style scoped lang="scss">
  :deep() .btn-dropdown {
    .drp-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  :deep() .overview-paper {
    max-width: 100%;
  }

  :deep() .button-dropdown {
    justify-content: center;
    background: none;

    &:hover {
      background: none;
    }

    &__toggle-container {
      justify-content: stretch;
      align-items: stretch;
      background: none;

      .button {
        @extend %flex-center;
        background: none;
        flex: 1 1 auto;
        padding: 0;
        transition: none;

        &:hover {
          color: $color-gray-25;
          background: none;
        }

        &:active {
          background: none;
        }
      }
    }
  }
</style>
