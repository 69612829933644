<script>
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 * DeleteTagModal
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'DeleteTagModal',
  components: {
    EzConfirmationModal,
    EzButton,
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onCancel() {
      this.$emit('cancel');
    },
    onClose() {
      this.$emit('onClose');
    },
    onDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="modal" type="red" icon="question" @close="onClose">
    <template #title>Delete this tag?</template>
    <template #content>You are about to remove this tag from your list.</template>
    <template #footer>
      <ez-button
        type="link"
        @click="onCancel"
      >
        Cancel
      </ez-button>
      <ez-button
        type="red"
        @click="onDelete"
      >
        Delete Tag
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
