<template>
  <ez-form-modal ref="modal" @close="onModalClose">
    <template #title>
      {{isNewTier ? 'Add Special Tier Price' : 'Edit Special Tier Price'}}
    </template>
    <template #content>
      <ez-form
        submitType="multipart"
        ref="form"
        method="patch"
        :formKey="formKey"
        :action="formAction"
        :loading-key="loadingKeyForm"
        @success="onSubmitSuccess">
        <template v-if="isNewTier">
          <special-tier-form
            :formKey="formKey"
            :name="`specialTierPricings[${(product.specialTierPricings || []).length}]`"
            :product="product"
            :invalidSelected="invalidSelected"
            @selectedVenues="onSelectedVenues"
            :data-cy="tiersDataCy"
          />
        </template>
        <section
          v-for="(specialTier, ind) in product.specialTierPricings"
          :key="ind">
          <template v-if="!isNewTier && specialTier.id === specialTierId">
            <special-tier-form
              :formKey="formKey"
              :name="`specialTierPricings[${ind}]`"
              :product="product"
              :specialTier="specialTier"
              :invalidSelected="invalidSelected"
              @selectedVenues="onSelectedVenues"
              :data-cy="tiersDataCy"
            />
          </template>
          <template v-else>
            <input
              type="hidden"
              :name="`specialTierPricings[${ind}][discountType]`"
              :value="specialTier.discountType"/>
            <input
              type="hidden"
              :name="`specialTierPricings[${ind}][name]`"
              :value="specialTier.name"/>
            <input
              v-for="(venueId, venueInd) in specialTier.venues"
              type="hidden"
              :key="venueId"
              :name="`specialTierPricings[${ind}][venueIds][${venueInd}]`"
              :value="venueId"/>
            <div
              v-for="(tier, tierInd) in specialTier.tiers"
              :key="tier.id">
              <input
                type="hidden"
                :name="`specialTierPricings[${ind}][tiers][${tierInd}][minQuantity]`"
                :value="tier.minQuantity"/>
              <input
                v-if="!tier.marketPrice"
                type="hidden"
                :name="`specialTierPricings[${ind}][tiers][${tierInd}][value]`"
                :value="tier.value"/>
              <input
                type="hidden"
                :name="`specialTierPricings[${ind}][tiers][${tierInd}][marketPrice]`"
                :value="tier.marketPrice"/>
              <input
                type="hidden"
                :value="tier.id"
                :name="`specialTierPricings[${ind}][tiers][${tierInd}][id]`"/>
            </div>
            <input
              type="hidden"
              :value="specialTier.id"
              :name="`specialTierPricings[${ind}][id]`"/>
          </template>
        </section>
      </ez-form>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="dataCyAttr('BUTTON__CANCEL')"
      >
        Cancel
      </ez-button>
      <ez-button
        formType="button"
        @click="onSaveChangesClick"
        :data-cy="dataCyAttr('BUTTON__SAVE')"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<script>
import { clone } from '@/util/utils';
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';
import SpecialTierForm from './SpecialTierForm.vue';

/**
 * This component handles creating and/or updating special tier.
 * Special tiers is selected from the array by passing its id (specialTierId prop).
 * The rest of the tiers are passed as additional data via hidden inputs
 * */
export default {
  components: {
    EzButton,
    EzForm,
    EzFormModal,
    SpecialTierForm,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    specialTierId: {
      type: Number,
      default: null,
    },
    distributorId: {
      type: Number,
      default: null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      formKey: 'special-tier',
      loadingKeyForm: LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
      selectedVenues: [],
      invalidSelected: false,
    };
  },
  computed: {
    isNewTier() {
      return this.specialTierId === null;
    },
    isAdmin() {
      return localStorage.getItem('role') === 'admin';
    },
    formAction() {
      if (this.isAdmin) {
        return `/admin/distributors/${this.distributorId}/products/${this.product.id}`;
      }

      return `/distributor/products/${this.product.id}`;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
    tiersDataCy() {
      if (!this.hasDataCy) return {};

      return {
        INPUT__NAME: this.dataCyAttr('INPUT__NAME'),
        TEXT__OUTLET: this.dataCyAttr('TEXT__OUTLET'),
        LABEL__SET_PRICE: this.dataCyAttr('LABEL__SET_PRICE'),
        LABEL__SET_DISCOUNT: this.dataCyAttr('LABEL__SET_DISCOUNT'),
        BUTTON__ADD_TIER: this.dataCyAttr('BUTTON__ADD_TIER'),
        TIER_ITEM: {
          BUTTON__REMOVE_TIER: this.dataCyAttr('BUTTON__REMOVE_TIER'),
          INPUT__NUMBER_OF_PRODUCTS: this.dataCyAttr('INPUT__NUMBER_OF_PRODUCTS'),
          INPUT__VALUE: this.dataCyAttr('INPUT__VALUE'),
        },
        INPUT__OUTLET_SEARCH: this.dataCyAttr('INPUT__OUTLET_SEARCH'),
        BUTTON__OUTLET_RESULT: this.dataCyAttr('BUTTON__OUTLET_RESULT'),
        TEXT__OUTLET_RESULT_NAME: this.dataCyAttr('TEXT__OUTLET_RESULT_NAME'),
        TEXT__SELECTED_OUTLET: this.dataCyAttr('TEXT__SELECTED_OUTLET'),
        BUTTON__REMOVE_SELECTED_OUTLET: this.dataCyAttr('BUTTON__REMOVE_SELECTED_OUTLET'),
      };
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onSubmitSuccess({ data }) {
      this.close();
      flash.success({ title: 'Successfully updated special tiers for the product' });
      this.$emit('success', data.data);
    },
    onSaveChangesClick() {
      const invalidSelected = !this.selectedVenues.length;
      this.invalidSelected = invalidSelected;

      if (!invalidSelected) this.submitForm();
    },
    onModalClose() {
      this.$emit('close');
    },
    onSelectedVenues(venues) {
      this.selectedVenues = clone(venues);
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped>

</style>
