<script>
/**
   * Simple functional component that will provide two buttons select and deselect.
   * It will return events based on what is clicked. It is up to the parent what to do with that.
   * @version 1.0.0
   * @since 2.0.1
   */

export default {
  functional: true,
  props: {
    hasSelect: {
      type: Boolean,
      default: true,
      required: false,
    },
    hasDeselect: {
      type: Boolean,
      default: true,
      required: false,
    },
    selectAllText: {
      type: String,
      default: 'Select All',
    },
    deselectAllText: {
      type: String,
      default: 'Deselect All',
    },
    selectDataCy: {
      type: String,
      required: false,
      default: '',
    },
    deselectDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  render(h, context) {
    const {
      props: {
        hasSelect,
        hasDeselect,
        selectAllText,
        deselectAllText,
        selectDataCy,
        deselectDataCy,
      },
      listeners: { selectAll, deselectAll },
    } = context;
    let selectButton = null;
    let deselectButton = null;

    const selectIcon = h('font-awesome-icon', {
      props: { icon: 'check' },
      class: 'sd__icon sd__icon--select',
    });
    const selectText = h('span', null, selectAllText);

    const deselectIcon = h('font-awesome-icon', {
      props: { icon: 'times' },
      class: 'sd__icon sd__icon--deselect',
    });
    const deselectText = h('span', null, deselectAllText);

    if (hasSelect) {
      selectButton = h('button', {
        class: 'sd__button',
        on: {
          /**
           * When `selectAll` is clicked.
           * @event selectAll
           */
          click: selectAll,
        },
        attrs: { 'data-cy': selectDataCy },
      }, [selectIcon, selectText]);
    }
    if (hasDeselect) {
      deselectButton = h('button', {
        class: 'sd__button',
        on: {
          /**
           * When `selectAll` is clicked.
           * @event deselectAll
           */
          click: deselectAll,
        },
        attrs: { 'data-cy': deselectDataCy },
      }, [deselectIcon, deselectText]);
    }
    return h('div', { class: 'sd' }, [selectButton, deselectButton]);
  },
};
</script>

<style lang="scss" scoped>
$margin-left: 8px;

.sd {
  &__icon {
    margin-right: 4px;

    &--select { color: $color-primary-green; }
    &--deselect { color: $color-primary-red; }
  }
  &__button {
    position: relative;
    cursor: pointer;

    & + & {
      margin-left: 2 * $margin-left;

      &:before {
        content: '';
        @include absolute(left -1 * $margin-left);
        height: 12px;
        width: 1px;
        background-color: #E9EBF2;
      }
    }
  }
}
</style>
