<template>
  <group-form
    ref="form"
    :columnMode="true"
    :group="draft"
    :formAction="action"
    @submitSuccess="onSubmitSuccess"
    @removeImage="onRemoveImage"
    @currencySelected="enableNextStep()"
  />
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import GroupForm from '@/views/common/venue-groups/Form.vue';

export default {
  mixins: [wizardListenerMixin],
  components: {
    GroupForm,
  },
  computed: {
    ...mapState('entities/groups', ['draft']),
    isExistingGroup() {
      return this.draft && this.draft.id;
    },
    action() {
      if (this.isExistingGroup) return `/venue-groups/${this.draft.id}`;
      return '/venue-groups';
    },
  },
  methods: {
    ...mapMutations('entities/groups', ['UPDATE_DRAFT']),
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    onNextStep() {
      this.$refs.form.submit();
    },
    onSubmitSuccess({ data }) {
      this.UPDATE_DRAFT(data.data);
      this.$emit('stepCompleted');
    },
    onRemoveImage() {
      this.UPDATE_DRAFT({ logo: null });
    },
  },
  mounted() {
    this.fetchDefaultImages();
  },
};
</script>

<style scoped>

</style>
