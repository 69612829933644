<script>
import EzInput from '@/components/ui/Input';
import uuid from 'uuid/v4';

/**
 * VTagEditor
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'VTagEditor',
  components: {
    EzInput,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    colors: {
      type: Array,
      required: true,
    },
    formKey: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    selectedValue() {
      const color = this.value?.color ?? this.colors?.[0] ?? { id: uuid(), colors: ['#000', '#222'] };
      return {
        name: this.value?.name ?? '',
        color,
      };
    },
    selectedColorStyleObject() {
      return this.selectColorStyleObject(this.selectedValue.color);
    },
  },
  methods: {
    clearErrors() {
      this.$refs.tagNameInput.clearErrors();
    },
    updateName(name) {
      this.$emit('change', {
        name,
        color: this.selectedValue.color,
      });
    },
    selectColorStyleObject(color) {
      return {
        border: `2px solid ${color?.colors[0]}`,
        backgroundColor: `${color?.colors[1]}`,
      };
    },
    selectColor(color) {
      this.$emit('change', {
        name: this.selectedValue.name,
        color,
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="name-input">
      <span class="color-indicator" :style="selectedColorStyleObject">
      </span>
      <form class="input" @submit.prevent>
        <ez-input
        :formKey="formKey"
        name="name"
        :value="selectedValue.name"
        :placeholder="`Enter ${$t('global.tag')} name`"
        @onChange="updateName"
        ref="tagNameInput"
        >
        </ez-input>
      </form>
    </div>
    <div class="color-selection">
      <p>Choose color</p>
      <div class="color-selection__display">
        <div
          v-for="(color) in colors"
          :key="color.id"
          @click="selectColor(color)"
          :class="{ 'color-indicator--selected': selectedValue.color.id === color.id }"
        >
          <div class="color-indicator" :style="selectColorStyleObject(color)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.name-input {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .input {
    width: 100%;
  }
}

.color-indicator {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 12px;
}

.color-selection__display {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #e1e5ed;
  border-radius: 1px;

  div {
    cursor: pointer;
    padding-bottom: 12px;

    .color-indicator {
      margin-right: 0;
    }
  }

  .color-indicator--selected {
    border-bottom: 1px solid #4D7CFE;
    border-radius: 1px;
  }
}
</style>
