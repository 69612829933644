<template>
  <div class="p-info">
    <ez-form
      :action="isAdmin ?
      `admin${$helpers.remapPath($route.path)}`
      : `/distributor/products/${productId || ''}`"
      :loading-key="loadingKeyForm"
      :formKey="formKey"
      method="patch"
      submitType="multipart"
      :additional-data="{
        ...(imgModified && {image: ''}),
        ...priceUnits,
        ...tags,
        ...((tax && !isTaxDefault)  ? { tax } : { tax: null}),
      }"
      @success="onSubmitSuccess"
      @error="onSubmitError"
      ref="productForm"
      class="form"
    >
      <section class="product-form__details">
        <ez-image-upload
          v-if="canEditProduct"
          :formKey="formKey"
          :previewUrl="(productCopyCmp.image || defaultImage) ||
          (productCopyCmp.category.image || defaultImage)"
          :isDefaultImage="productCopyCmp.isDefaultImage"
          name="image"
          @change="imgModified = true"
          @removeImage="onRemoveImage(productCopyCmp)"
          :add-button-data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.BUTTON__UPLOAD_IMAGE"
          :remove-button-data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.BUTTON__REMOVE_IMAGE"
        >
          <template #addImage>
            <span>Change Product Image</span>
          </template>
          <template #removeImage>
            <font-awesome-icon icon="times" />
            <span>Remove Image</span>
          </template>
        </ez-image-upload>
        <ez-entity-info
          v-else
          :imgUrl="product.image"
          :product-id="product.id"
          :imgHasBorder="true"
          imgBorderRadius=".4rem"
          imgWidth="4rem"
          title="You don't have permission to edit the product image."
          imgHeight="4rem"
          class="form__image"
        />
        <ez-input
          :formKey="formKey"
          name="name"
          label="Product name*"
          :value="product.name"
          :disabled="isTradeGecko || !canEditProduct"
          placeholder="E.g. Elephant Sloe Gin 0.5L"
         :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.INPUT__PRODUCT_NAME"
        />
        <hr />
        <p class="section-title">{{ $t('global.product') }} Details</p>
        <ez-input
          :formKey="formKey"
          name="sku"
          label="SKU number"
          :value="product.sku"
          :disabled="isTradeGecko || !canEditProduct"
          placeholder="E.g. 09120000"
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.INPUT__PRODUCT_SKU"
        />
        <ez-category-filter
          name="categoryId"
          label="Category"
          isFullWidth
          :selected="product.categoryId"
          :disabled="isTradeGecko || !canEditProduct"
          :is-parent-disabled="true"
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.BUTTON__PRODUCT_CATEGORY"
        />
        <v-product-group-settings name="productGroupId" :selectedId="product.productGroup?.id" />
        <ez-textarea
          :formKey="formKey"
          name="description"
          label="Description"
          :value="product.description"
          :disabled="isTradeGecko || !canEditProduct"
          placeholder="E.g. It's very nice!"
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.TEXTAREA__PRODUCT_DESCRIPTION"
        />
        <div class="import-type mt-12">
          <span class="label mb-4">Import Type</span>
          <span
            class="type-value"
            :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.TEXT__IMPORT_TYPE"
          >
            {{ product.importType | capitalize }}
          </span>
        </div>
        <hr />
      </section>
    </ez-form>
    <v-units-settings
      @onUnitChange="getUnitsAndProduct"
      @successDeleteUnit="getUnitsAndProduct"
      @unitsUpdated="unitsUpdated"
      @disabled="val => (disabled = val)"
      :units="units"
      :form-key="formKey"
      :product="product"
      :allow-negative-price="false"
      :show-cogs="!isProductTracked"
      :data-cy="unitsDataCy"
    />
    <hr />
    <div class="mb-16">
      <p class="section-title">Tax Rate</p>
    </div>
    <div class="tax-wrapper">
      <ez-input
        class="mt-16 mb-12"
        :formKey="formKey"
        placeholder="Tax Rate"
        label="Tax Rate"
        type="number"
        name="tax"
        step="any"
        :value="product.tax"
        :disabled="isDistributorXeroConnected || isTaxDefault"
        @onChange="(ev) => tax = ev"
      >
        <template #suffix>%</template>
      </ez-input>
      <ez-button
        class="ml-12 mb-12"
        type="secondary"
        v-if="isDistributorXeroConnected"
        @click="goToAccountingIntegration"
      >
        Settings
      </ez-button>
    </div>
    <ez-checkbox
      v-if="!isDistributorXeroConnected"
      :form-key="formKey"
      name="isTaxDefault"
      label="Default Tax Rate"
      @change="onDefaultTaxRateChange"
      :checked="isTaxDefault"
    />
    <div v-if="!isAdmin" class="tags">
      <hr />
      <p class="section-title">Add Tags</p>
      <span class="button-description">Create or Add Product Tag(s)</span>
      <ez-button v-if="!productTags.length" type="secondary" is-full-width @click="openTagsModal"
        :isLoading="isLoading">
        <font-awesome-icon icon="plus" />
        Add Tags
      </ez-button>
      <ez-button v-else type="secondary" is-full-width @click="openTagsModal" class="height-fit-content"
        :isLoading="isLoading">
        <div class="all-tags">
          <v-tag
            v-for="tag in productTags"
            :key="tag.id"
            :color="tag.color.colors[0]"
            :backgroundColor="tag.color.colors[1]"
            @click.stop="onClick(tag)"
            canRemove
          >
            {{ tag.name }}
          </v-tag>
        </div>
        <font-awesome-icon icon="pen" class="icon-absolute" />
      </ez-button>
    </div>
    <add-tags-modal v-if="!isAdmin" ref="addTagsModal" @confirm="onAddConfirm" :tagType="TagType.Product"/>
    <footer>
      <ez-button
        v-if="canEditProduct || canEditPrice"
        :is-loading="isUpdating"
        @click="submitForm"
        class="mt-16"
        :disabled="disabled"
        :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.PRODUCT_INFO.BUTTON__SAVE_CHANGES"
      >
        Save Changes
      </ez-button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import Category from '@/models/Category';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import Product from '@/models/Product';
import EzTextarea from '@/components/ui/Textarea';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import EzEntityInfo from '@/components/ui/EntityInfo/';
import { clone, getCurrency } from '@/util/utils';
import { isAdmin, LOADING_KEY, TagType } from '@/util/constants';
import VUnitsSettings from '@/components/v3/patterns/VUnitsSettings';
import { getUnits } from '@/views/common/products/ProductsActions';
import VProductGroupSettings from '@/components/v3/elements/VProductGroupSettings/index.vue';
import VTag from '@/components/v3/elements/VTag';
import AddTagsModal from '@/components/v3/elements/VProductTagsSettings/AddTagsModal.vue';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';

/**
 * ProductInfo
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzEntityInfo,
    EzTextarea,
    EzCategoryFilter,
    EzForm,
    EzImageUpload,
    EzInput,
    EzButton,
    VUnitsSettings,
    VProductGroupSettings,
    VTag,
    AddTagsModal,
    EzCheckbox,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formAction: 'action',
      formKey: 'product-info',
      imgModified: false,
      loadingMsg: 'Loading...',
      loadingKeyForm: LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
      units: [],
      tax: null,
      priceUnits: {},
      disabled: true,
      supplierCy,
      productTags: [],
      productTagsIds: [],
      TagType,
      isTaxDefault: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    ...mapGetters('entities/users', ['isDistributorXeroConnected']),
    ...mapState('tags', ['selectedTags', 'selectedTagsIds']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_TAGS,
        LOADING_KEY.FETCH_TAG_COLORS,
      ]);
    },
    isUpdating() {
      return this.getLoading(this.loadingKeyForm);
    },
    me() {
      return this.loggedUser.distributor;
    },
    isAdmin() {
      return isAdmin();
    },
    title() {
      return this.isNewTier ? `Add ${this.$t('global.venue')} Specific Pricing` : 'Edit Special Tier Prices';
    },
    categories() {
      return Category.query()
        .where('parentId', v => !!v)
        .all();
    },
    product() {
      return Product.find(this.productId);
    },
    productCopyCmp() {
      return clone(
        Product.query()
          .whereId(this.productId)
          .with('category')
          .first(),
      );
    },
    currencySymbol() {
      const { currency } = this.product || {};
      const { symbol } = currency || getCurrency() || {};
      return symbol;
    },
    isTradeGeckoConnected() {
      return this.me.isTradeGecko;
    },
    isTradeGecko() {
      return (this.product.importType === 'trade_gecko') && this.isTradeGeckoConnected;
    },
    defaultImage() {
      return this.getDefaultImage('category');
    },
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('entities/products', [
      'getProduct',
      'getAllSpecialTierForProduct',
      'getSpecialTierDraftIndex',
    ]),
    ...mapState('entities/products', ['draft', 'specialTierDraft']),
    canEditProduct() {
      return this.$permission.has('editProductInfo');
    },
    canEditPrice() {
      return this.$permission.has('editProductPricing');
    },
    isProductTracked() {
      return this.product.inventoryTracked;
    },

    unitsDataCy() {
      const { PRODUCT_INFO } = supplierCy.PRODUCTS.SINGLE_PRODUCT;
      return {
        cogs: PRODUCT_INFO.INPUT__COGS,
        price: PRODUCT_INFO.INPUT__PRICE,
        minimumQuantity: PRODUCT_INFO.INPUT__MINIMUM_QUANTITY,
        orderingQuantityIncrement: PRODUCT_INFO.INPUT__QUANTITY_INCREMENT,
        orderingUnitId: PRODUCT_INFO.BUTTON__SALES_UNIT,
        priceUnitId: PRODUCT_INFO.BUTTON__PRICE_UNIT,
        marketPrice: PRODUCT_INFO.LABEL__MARKET_PRICE,
        priceUnit: PRODUCT_INFO.LABEL__PRICE_UNIT,
      };
    },
    tags() {
      if (!this.productTagsIds.length) return { tagIds: '' };
      const tagIdsObject = {};
      this.productTagsIds.forEach((id, index) => {
        tagIdsObject[`tagIds[${index}]`] = id;
      });
      return tagIdsObject;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'fetchDistributorProduct',
      'removeDistributorProductImage',
      'removeProductForDistributor',
      'fetchProductForDistributor',
      'removeProductImageForDistributor',
    ]),
    ...mapMutations('entities/products', [
      'NEW_DRAFT',
      'CLEAR_DRAFT',
      'NEW_DEFAULT_TIER_ITEM',
      'REMOVE_DEFAULT_TIER_ITEM',
      'UPDATE_DEFAULT_TIER',
      'NEW_SPECIAL_TIER_DRAFT',
      'REMOVE_SPECIAL_TIER',
    ]),
    ...mapMutations('tags', [
      'REMOVE_SELECTED_TAG',
      'SET_SELECTED_TAGS',
    ]),
    ...mapActions('tags', [
      'fetchTagsByType',
      'fetchTagColors',
    ]),
    submitForm() {
      this.$refs.productForm.onSubmit();
    },
    onSubmitSuccess({ data }) {
      this.productCopyCmp.isDefaultImage = true;
      Product.insertOrUpdate({ data: data.data });
      flash.success({
        title: 'Product successfully updated!',
      });
      this.getUnitsAndProduct();
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
      const { tags } = this.product;
      this.productTags = clone(tags);
      this.productTagsIds = tags.map(item => item.id);
    },
    onAddDefaultTier() {
      this.NEW_DEFAULT_TIER_ITEM();
    },
    onRemoveDefaultTier({ id }) {
      this.REMOVE_DEFAULT_TIER_ITEM(id);
    },
    onDefaultTierChange(payload) {
      this.UPDATE_DEFAULT_TIER(payload);
    },
    onRemoveImage(productCopyCmp) {
      // eslint-disable-next-line
      productCopyCmp.image ? (productCopyCmp.image = null) : (productCopyCmp.category.image = null);
      productCopyCmp.isDefaultImage = true;
      this.imgModified = true;
    },
    onAddSpecialTier() {
      this.isNewTier = true;
      this.NEW_SPECIAL_TIER_DRAFT();
      this.$refs.editSpecialTierModal.open();
    },
    onEditSpecialTier(tier, index) {
      this.isNewTier = false;
      this.specialTierIndex = index;
      this.NEW_SPECIAL_TIER_DRAFT(tier);
      this.$refs.editSpecialTierModal.open();
    },
    onRemoveSpecialTier({ id }) {
      this.REMOVE_SPECIAL_TIER(id);
    },
    async getUnitsAndProduct() {
      const { id: distributorId } = this.$route.params;

      try {
        const [
          { data: { data } },
        ] = await Promise.all([
          getUnits({ distributorId }),
          this.fetchProduct(),
        ]);
        this.units = data;
      } catch (e) {
        console.error(e);
      }
    },
    unitsUpdated(data) {
      this.priceUnits = data;
    },
    onDefaultTaxRateChange(val) {
      this.isTaxDefault = val;
    },
    async fetchProduct() {
      const { productId } = this;

      if (productId > -1) {
        if (this.isAdmin) {
          const { id } = this.$route.params;
          this.fetchProductForDistributor({
            id,
            productId,
          }).then(() => this.NEW_DRAFT(productId));

          return;
        }
        this.fetchDistributorProduct({ id: productId, loadingKey: '' }).then(() => this.NEW_DRAFT(productId));
      } else {
        this.NEW_DRAFT();
      }
    },
    async openTagsModal() {
      await this.fetchTagsByType({ query: { type: TagType.Product } });
      await this.fetchTagColors();
      this.SET_SELECTED_TAGS(this.productTagsIds);
      this.$refs.addTagsModal.open();
    },
    onClick(tag) {
      this.REMOVE_SELECTED_TAG(tag);
      this.productTagsIds = this.productTagsIds.filter(item => item !== tag.id);
      this.productTags = this.productTags.filter(item => item.id !== tag.id);
    },
    onAddConfirm() {
      this.productTags = clone(this.selectedTags);
      this.productTagsIds = clone(this.selectedTagsIds);
      this.$refs.addTagsModal.close();
    },
    goToAccountingIntegration() {
      const routerData = this.$router.resolve({
        name: 'distributor-xero-products',
      });
      window.open(routerData.href, '_blank');
    },
  },
  async created() {
    await this.getUnitsAndProduct();
    const { tags } = this.product;
    this.productTags = clone(tags);
    this.productTagsIds = tags.map(item => item.id);
    this.SET_SELECTED_TAGS(this.productTagsIds);
    this.tax = this.product.tax;
    this.isTaxDefault = this.product.isTaxDefault;
  },
};
</script>

<style lang="scss" scoped>
  .form {
    .ez-image-upload {
      margin-bottom: 1rem;
    }

    .input-group + .input-group {
      margin-top: .75rem;
    }

    .form-buttons {
      display: flex;
    }

    .form-help {
      margin-bottom: 1rem;

      h4 {
        margin-top: 0;
        margin-bottom: .5rem;
      }

      p {
        @include font-size(12px);
        color: $color-gray-6C;
        margin: 0;
      }
    }

    .input-group + .notification-number-input.input-group {
      margin-top: .5em;
    }

    .notification-number-input {
      :deep() .input-group__label {
        display: none;
      }

      &.input-group + .input-group {
        margin-top: 1em;
      }
    }

    hr {
      border: 0;
      border-top: 1px dashed #DEE1E4;
      margin: 1.25rem 0;
    }

    footer :deep() .button {
      margin-top: 1em;
    }

    &__image {
      margin-bottom: 1rem;
    }

    .section-title {
      @include font-size(14px, 22px);
      color: $color-gray-25;
    }
    .import-type {
      display: flex;
      flex-direction: column;
      .label {
        color: $color-gray-6C;
        @include font-size(12px, 18px);
      }
      .type-value {
        @include font-size(14px, 20px);
        color: $color-gray-25;
      }
    }
  }
  .all-tags {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 14px;
  }

  .button.height-fit-content {
    height: auto;
    padding-right: 42px;
    position: relative;
  }

  .icon-absolute {
    position: absolute;
    top: 10px;
    right: 12px;
  }

  .button-description {
    color: #6C7995;
    font-size: 12px;
    line-height: 18px;

    & + button {
      margin-top: 6px;
    }
  }

.tax-wrapper {
  :deep() .input-group {
    flex: 1;
  }
  display: flex;
  align-items: flex-end;
  width: 100%;
}
</style>
