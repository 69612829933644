<template>
  <ez-form-modal ref="modal" @open="onModalOpen">
    <template #title>Add Tier Pricing</template>
    <template #content>
      <ez-form
        :form-key="formKey"
        :action="formAction"
        :loading-key="loadingKeyForm"
        submit-type="multipart"
        ref="form"
        method="patch"
        @success="onSubmitSuccess">
        <ez-tier-list
          v-if="tierPricingDraft"
          :key="listKey"
          :form-key="formKey"
          :tier-pricing="tierPricingDraft"
          name="defaultTierPricing"
          :currency="product && product.currency"
          :ordering-unit="orderingUnit"
          :price-unit="priceUnit"
          :is-market-price="product && product.marketPrice"
          @change="onDefaultTierPricingChange"
          :data-cy="tiersDataCy"
        />
      </ez-form>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="dataCyAttr('BUTTON__CANCEL')"
      >
        Cancel
      </ez-button>
      <ez-button
        formType="button"
        @click="onSaveChangesClick"
        :data-cy="dataCyAttr('BUTTON__SAVE')"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<script>
import uuid from 'uuid/v4';
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import EzTierList from '@/components/ui/Tiers/TierList.vue';
import Product from '@/models/Product';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';
import { clone } from '@/util/utils';

export default {
  components: {
    EzButton,
    EzFormModal,
    EzForm,
    EzTierList,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    distributorId: {
      type: Number,
      default: null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      formKey: 'tier-form',
      listKey: uuid(),
      tierPricingDraft: null,
      loadingKeyForm: LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
    };
  },
  computed: {
    isAdmin() {
      return localStorage.getItem('role') === 'admin';
    },
    formAction() {
      if (this.isAdmin) {
        return `/admin/distributors/${this.distributorId}/products/${this.product.id}`;
      }

      return `/distributor/products/${this.product.id}`;
    },
    hasTiers() {
      return !!this.product.defaultTierPricing.tiers.length;
    },
    orderingUnit() {
      return this.product.orderingUnit;
    },
    priceUnit() {
      return this.product.priceUnit;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
    tiersDataCy() {
      if (!this.hasDataCy) return {};

      return {
        LABEL__SET_PRICE: this.dataCyAttr('LABEL__SET_PRICE'),
        LABEL__SET_DISCOUNT: this.dataCyAttr('LABEL__SET_DISCOUNT'),
        BUTTON__ADD_TIER: this.dataCyAttr('BUTTON__ADD_TIER'),
        TIER_ITEM: {
          BUTTON__REMOVE_TIER: this.dataCyAttr('BUTTON__REMOVE_TIER'),
          INPUT__NUMBER_OF_PRODUCTS: this.dataCyAttr('INPUT__NUMBER_OF_PRODUCTS'),
          INPUT__VALUE: this.dataCyAttr('INPUT__VALUE'),
        },
      };
    },
  },
  methods: {
    open() {
      this.tierPricingDraft = clone(this.product.defaultTierPricing);
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onSubmitSuccess({ data }) {
      Product.update({ data: data.data })
        .then(() => {
          this.close();
        });
      flash.success({ title: 'Successfully updated the default tier for the product.' });
      this.$emit('success', data.data);
    },
    onSaveChangesClick() {
      this.submitForm();
    },
    onModalOpen() {
      this.listKey = uuid();
    },
    onDefaultTierPricingChange({ discountType }) {
      this.tierPricingDraft.discountType = discountType;
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped lang="scss">
</style>
