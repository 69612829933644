<template>
  <div class="ez-special-tier-form">
    <ez-input
      :form-key="formKey"
      :name="`${name}[name]`"
      :value="specialTierDraft.name"
      placeholder="Enter Special Tier Name"
      label="Special Tier Name"
      :data-cy="dataCyAttr('INPUT__NAME')"
    />
    <ez-tier-list
      v-if="specialTierDraft && Object.keys(specialTierDraft).length"
      :form-key="formKey"
      :tier-pricing="specialTierDraft"
      :name="name"
      :currency="product && product.currency"
      :ordering-unit="orderingUnit"
      :price-unit="priceUnit"
      :is-market-price="product && product.marketPrice"
      @change="onDefaultTierPricingChange"
      :data-cy="tiersDataCy"
    />
    <h4>ASSIGNED {{ $t('global.venues') | uppercase }}</h4>

    <div v-if="!!venuesError" class="ez-special-tier-form__venue-list-item-error">
      {{ venuesError }}
    </div>

    <ez-autocomplete
      ref="autocomplete"
      source="/distributor/search/venues"
      searchProperty="name"
      :requestParams="{ connected: true }"
      :label="`${$t('global.venue')} to assign`"
      :placeholder="`Search for ${$t('global.venue')}`"
      @selected="onVenueSelect"
      :data-cy="dataCyAttr('INPUT__OUTLET_SEARCH')"
      :result-data-cy="dataCyAttr('BUTTON__OUTLET_RESULT')"
      class="search-input"
    >
      <template #result="{ result, index }">
        <v-venue-entity-info :venue="result" :data-cy="`${dataCyAttr('TEXT__OUTLET_RESULT_NAME')}-${index}`" />
      </template>

      <template #icon>
        <font-awesome-icon icon="search" class="search-input__icon" />
      </template>
    </ez-autocomplete>

    <ul>
      <li
        v-for="(venue, index) in selectedVenues"
        :key="venue.id"
        class="ez-special-tier-form__venue-list-item"
      >
        <ez-selected-venue
          :venue="venue"
          @remove="toggleSelectedVenues(venue)"
          only-basic-info
          :data-cy="`${dataCyAttr('TEXT__SELECTED_OUTLET')}-${index}`"
          :remove-button-data-cy="`${dataCyAttr('BUTTON__REMOVE_SELECTED_OUTLET')}-${index}`"
        />
        <div
          v-if="!!singleVenueError(venue.id)"
          class="ez-special-tier-form__venue-list-item-error"
        >
          {{ singleVenueError(venue.id) }}
        </div>
      </li>
    </ul>

    <input
      type="checkbox"
      v-for="(venue, venueInd) in selectedVenuesIds"
      :key="`${venue}-${venueInd}`"
      :value="venue"
      :name="`${name}[venueIds][${venueInd}]`"
      v-model="selectedVenuesIds"
    />
    <input type="hidden" :value="specialTierDraft.id" :name="`${name}[id]`" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { clone } from '@/util/utils';
import { EzSelectedVenue } from '@/components/ui/SelectedItem';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete.vue';
import EzTierList from '@/components/ui/Tiers/TierList.vue';
import EzInput from '@/components/ui/Input';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';

export default {
  components: {
    EzSelectedVenue,
    EzAutocomplete,
    EzTierList,
    EzInput,
    VVenueEntityInfo,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    specialTier: {
      type: Object,
      default: () => ({
        discountType: 'price',
        tiers: [],
        venues: [],
        isNew: true,
      }),
    },
    invalidSelected: {
      type: Boolean,
      required: true,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedVenues: [],
      specialTierDraft: {},
    };
  },
  computed: {
    ...mapGetters('errors', ['getFormErrors']),
    tierErrors() {
      return this.getFormErrors(this.formKey) || {};
    },
    venuesError() {
      if (this.invalidSelected) {
        return `At least one ${this.$t('global.venue').toLowerCase()} must be assigned.`;
      }

      const errors = this.tierErrors[`${this.name}[venueIds]`];

      return Array.isArray(errors) ? errors.join(' ') : errors;
    },
    singleVenueError() {
      return (id) => {
        const selectedVenueIndex = this.selectedVenues.findIndex(venue => venue.id === id);

        const errors = this.tierErrors[`${this.name}[venueIds][${selectedVenueIndex}]`];

        return Array.isArray(errors) ? errors.join(' ') : errors;
      };
    },
    selectedVenuesIds() {
      return this.selectedVenues.map(venue => venue.id);
    },
    orderingUnit() {
      return this.product.orderingUnit;
    },
    priceUnit() {
      return this.product.priceUnit;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
    tiersDataCy() {
      if (!this.hasDataCy) return {};

      const tierItem = this.dataCyAttr('TIER_ITEM');

      return {
        LABEL__SET_PRICE: this.dataCyAttr('LABEL__SET_PRICE'),
        LABEL__SET_DISCOUNT: this.dataCyAttr('LABEL__SET_DISCOUNT'),
        BUTTON__ADD_TIER: this.dataCyAttr('BUTTON__ADD_TIER'),
        TIER_ITEM: {
          BUTTON__REMOVE_TIER: tierItem.BUTTON__REMOVE_TIER,
          INPUT__NUMBER_OF_PRODUCTS: tierItem.INPUT__NUMBER_OF_PRODUCTS,
          INPUT__VALUE: tierItem.INPUT__VALUE,
        },
      };
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERROR']),
    toggleSelectedVenues(venue) {
      const existingVenueInd = this.selectedVenues.findIndex(v => v.id === venue.id);

      if (existingVenueInd === -1) {
        this.selectedVenues.push(venue);
        return;
      }

      const venueField = `${this.name}[venueIds]`;

      this.CLEAR_ERROR({ formKey: this.formKey, field: venueField });

      this.selectedVenues.splice(existingVenueInd, 1);
    },
    onDefaultTierPricingChange({ discountType }) {
      this.specialTierDraft.discountType = discountType;
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
    onVenueSelect(venue) {
      this.toggleSelectedVenues(venue.selected);
    },
  },
  mounted() {
    this.selectedVenues = clone(this.specialTier.assignedVenues || []);
    this.specialTierDraft = clone(this.specialTier);
  },
  watch: {
    selectedVenues: {
      immediate: true,
      handler(venues) {
        this.$emit('selectedVenues', venues);
      },
    },
  },
};
</script>

<style scoped lang="scss">
$error-color: #ea4b5d;

.ez-special-tier-form__venue-entity {
  max-width: 315px;
}

.ez-special-tier-form {
  .search-input {
    margin-bottom: $spacing-12;

    &__icon {
      margin-left: $spacing-12;
      color: $color-gray-6C;
    }

    :deep() .autocomplete {
      &__label {
        text-transform: initial;
      }

      &__input {
        @include font-size(14px, 20px);
      }
    }
  }

  &__venue-list-item :deep() {
    margin-top: $spacing-08;

    .entity-info__text {
      & > div > div:nth-child(1) span {
        @include font-size(14px, 20px);
      }

      & > div > div:nth-child(2) {
        display: none;
      }
    }
  }

  &__venue-list-item-error {
    @include font-size(12px, 14px);
    margin-top: 0.5rem;
    color: $error-color;
  }

  :deep() .ez-tier-list {
    @include separator('both');
  }

  h4 {
    @extend %gray-label;
    margin: 0 0 1rem 0;
    font-weight: 500;
  }

  ul {
    @extend %ul-reset;
  }

  input[type='checkbox'] {
    display: none;
    position: absolute;
    top: -99999;
  }
}
</style>
