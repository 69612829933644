<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzInput from '@/components/ui/Input';
import VTag from '@/components/v3/elements/VTag';
import VTagEditor from '@/components/v3/elements/VTagEditor';
import DeleteTagModal from '@/components/v3/elements/VProductTagsSettings/DeleteTagModal.vue';
import { debounce } from '@/util/utils';
import { LOADING_KEY, NUMBER_OF_TAGS_TO_DISPLAY, TAGS_DISPLAY_TRESHOLD } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';


/**
 * EditTagsModal
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'EditTagsModal',
  components: {
    EzFormModal,
    EzButton,
    EzInput,
    VTag,
    VTagEditor,
    DeleteTagModal,
  },
  data() {
    return {
      disabled: true,
      term: '',
      tagToEdit: null,
      // Prevent reopening of 'AddTagsModal' if tag delete is in progress
      deleteFlag: false,
      tagsListExpanded: false,
    };
  },
  computed: {
    ...mapState('tags', [
      'allTags',
      'tagColors',
    ]),
    ...mapGetters('loading', ['getLoading']),
    filteredTags() {
      return this.allTags.filter(tag => (tag.name.toLowerCase().includes(this.term.toLowerCase()) && tag.canBeEdited));
    },
    numberOfTagsToDisplay() {
      if (this.filteredTags.length - NUMBER_OF_TAGS_TO_DISPLAY > TAGS_DISPLAY_TRESHOLD) return NUMBER_OF_TAGS_TO_DISPLAY;
      return this.filteredTags.length;
    },
    filteredTagsToDisplay() {
      if (!this.tagsListExpanded) {
        return this.filteredTags.slice(0, this.numberOfTagsToDisplay);
      }
      return this.filteredTags;
    },
    expandButtonCopy() {
      return this.tagsListExpanded ? 'Collapse List' : 'Show more tags';
    },
    expandButtonIcon() {
      return this.tagsListExpanded ? 'angle-up' : 'angle-down';
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_TAG);
    },
  },
  methods: {
    ...mapActions('tags', ['updateTag', 'deleteTag']),
    ...mapMutations('tags', [
      'ADD_SELECTED_TAG',
      'REMOVE_SELECTED_TAG',
      'UPDATE_TAG',
      'REMOVE_TAG',
    ]),
    open() {
      this.$refs.modal.open();
    },
    close(noClear = false) {
      if (this.$refs.tagEditor) this.$refs.tagEditor.clearErrors();
      this.$refs.modal.close();
      if (!noClear) this.clearEdit();
    },
    onClose() {
      if (!this.deleteFlag) this.clearEdit();
      this.$emit('onClose', this.deleteFlag);
    },
    async submit() {
      try {
        const { data } = await this.updateTag({
          id: this.tagToEdit.id,
          name: this.tagToEdit.name,
          colorId: this.tagToEdit.color.id,
          formKey: 'updateTag',
        });
        this.UPDATE_TAG(data.data);
        this.tagToEdit = null;
      } catch (e) {
        this.tagToEdit.name = null;
      }
    },
    onSearch: debounce(async function deb(term) {
      if (term !== this.term) {
        this.term = term;
      }
    }, 400),
    async onClick(tag) {
      this.tagToEdit = tag;
    },
    onTagChange(val) {
      this.tagToEdit = {
        ...this.tagToEdit,
        ...val,
      };
      this.disabled = false;
    },
    clearEdit() {
      this.tagToEdit = null;
      if (this.$refs.tagEditor) this.$refs.tagEditor.clearErrors();
    },
    openDeleteModal() {
      this.deleteFlag = true;
      this.$refs.deleteTagModal.open();
      this.close(true);
    },
    async confirmDelete() {
      try {
        await this.deleteTag({
          id: this.tagToEdit.id,
        });
        this.REMOVE_TAG(this.tagToEdit.id);
        this.tagToEdit = null;
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: 'Please try again.',
        });
      }
      this.$refs.deleteTagModal.close();
      this.open();
      this.deleteFlag = false;
    },
    cancelDelete() {
      this.deleteFlag = false;
      this.$refs.deleteTagModal.close();
      this.open();
    },
    closeDelete() {
      this.deleteFlag = false;
      this.open();
    },
    toggleListExpanded() {
      this.tagsListExpanded = !this.tagsListExpanded;
    },
  },
};
</script>

<template>
  <div>
    <ez-form-modal class="tags-modal" ref="modal" @close="onClose(deleteFlag)">
      <template #title>
        Edit Tags
        <font-awesome-icon v-if="tagToEdit && tagToEdit.canBeDeleted" @click="openDeleteModal()" icon="trash"/>
      </template>
      <template #content v-if="!tagToEdit">
        <ez-input formKey="tags" :value="term" name="search" class="search mb-16" :placeholder="`Search for a ${$t('global.tag')}`"
          @onChange="onSearch">
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <div>
          <div v-if="filteredTagsToDisplay.length" class="tags-modal__text">
            Select a tag to edit
          </div>
          <div v-else class="tags-modal__text">
            No more tags to show
          </div>
          <div class="all-tags">
            <v-tag
              v-for="tag in filteredTagsToDisplay"
              :key="tag.id"
              :color="tag.color.colors[0]"
              :backgroundColor="tag.color.colors[1]"
              @click="onClick(tag)"
            >
              {{ tag.name }}
            </v-tag>
            <span class="tags-number-indicator" v-if="filteredTags.length > numberOfTagsToDisplay && !tagsListExpanded">
              +{{ filteredTags.length - numberOfTagsToDisplay }}
            </span>
          </div>
          <ez-button
            v-if="filteredTags.length > numberOfTagsToDisplay"
            class="u-flex-h-center mt-16"
            @click="toggleListExpanded"
            isFullWidth
            type="link"
            formType="button"
          >
            <span>{{ expandButtonCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
          </ez-button>
        </div>
      </template>
      <template #content v-else>
        <v-tag-editor
          :value="tagToEdit"
          :colors="tagColors"
          @change="onTagChange"
          formKey="updateTag"
          ref="tagEditor"
        />
      </template>
      <template #footer v-if="!tagToEdit">
        <ez-button type="link" formType="button" @click="onClose">
          Cancel
        </ez-button>
      </template>
      <template #footer v-else>
        <ez-button type="link" formType="button" @click="clearEdit">
          Cancel
        </ez-button>
        <ez-button :isLoading="isLoading" :disabled="disabled" @click="submit" type="primary">
          Save Tag
        </ez-button>
      </template>
    </ez-form-modal>
    <delete-tag-modal
      ref="deleteTagModal"
      @delete="confirmDelete"
      @cancel="cancelDelete"
      @onClose="closeDelete"
    >
    </delete-tag-modal>
  </div>
</template>

<style lang="scss" scoped>
.tags-modal {
  @include z-index('modal', 10);

  .tags-modal__text {
    @include font-size($label-font-size, 18px);
    color: $color-gray-6C;
    margin-bottom: 18px;
  }

  .all-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }

  .tags-number-indicator {
    @include font-size(14px, 16px);
    color: $color-gray-6C;
  }

  :deep() {
    .modal {
      @include z-index('modal', 20);

      .modal__inner {
        .modal__text {
          overflow-y: inherit;
        }
      }

      &__header h2 {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: 12px;
        align-items: center;

        svg {
          color: $color-gray-6C;
          cursor: pointer;
          padding-bottom: 3px;
        }
      }
    }
  }
}
</style>
