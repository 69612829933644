<template>
  <ez-form-modal ref="modal" @open="onModalOpen">
    <template #title>Add Trade Deal</template>
    <template #content>
      <ez-form
        :form-key="formKey"
        :action="formAction"
        :loading-key="loadingKeyForm"
        submit-type="multipart"
        ref="form"
        method="patch"
        @success="onSubmitSuccess"
      >
        <div class="ez-tier-item">
          <span class="u-flex-space mb-12">
            <h4 class="ez-tier-item__title m-0">Offer</h4>
          </span>
          <div class="input-grid">
            <ez-mask-input
              class="ez-tier-item__number-container"
              type="input"
              label="Buy"
              placeholder="Enter Quantity"
              is-form-input
              :form-key="formKey"
              name="deals[0][buy]"
              :value="product?.deals?.[0]?.buy || 0"
              :precision="isQuantityUnitFractional ? 2 : 0"
              :price-prefix="false"
              :allow-negative-value="false"
            >
              <template #suffix>
                {{ formatUnitLabel(orderingUnit) }}
              </template>
            </ez-mask-input>
            <ez-mask-input
              class="ez-tier-item__number-container"
              type="input"
              label="Get"
              placeholder="Enter Quantity"
              is-form-input
              :form-key="formKey"
              name="deals[0][get]"
              :value="product?.deals?.[0]?.get || 0"
              :precision="isQuantityUnitFractional ? 2 : 0"
              :price-prefix="false"
              :allow-negative-value="false"
            >
              <template #suffix> {{ formatUnitLabel(orderingUnit) }} </template>
            </ez-mask-input>
            <ez-mask-input
              class="ez-tier-item__number-container"
              type="input"
              label="For"
              placeholder="Enter Price"
              is-form-input
              :form-key="formKey"
              name="deals[0][price]"
              :value="product?.deals?.[0]?.price || 0"
              :precision="2"
              :custom-prefix="currencySymbol"
              :allow-negative-value="false"
            >
            </ez-mask-input>
          </div>
        </div>
      </ez-form>
      <hr class="mb-0" />
    </template>
    <template #footer>
      <ez-button type="link" formType="button" @click="close"> Cancel </ez-button>
      <ez-button formType="button" @click="onSaveChangesClick"> Add Offer </ez-button>
    </template>
  </ez-form-modal>
</template>

<script>
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import Product from '@/models/Product';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { clone, getCurrency } from '@/util/utils';

export default {
  components: {
    EzButton,
    EzFormModal,
    EzForm,
    EzMaskInput,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    distributorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formKey: 'trade-deal-form',
      tradeDealDraft: null,
      loadingKeyForm: LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
    };
  },
  computed: {
    isAdmin() {
      return localStorage.getItem('role') === 'admin';
    },
    formAction() {
      if (this.isAdmin) {
        return `/admin/distributors/${this.distributorId}/products/${this.product.id}`;
      }

      return `/distributor/products/${this.product.id}`;
    },
    hasTradeDeals() {
      return !!this.product.deals.length;
    },
    orderingUnit() {
      return this.product.orderingUnit;
    },
    priceUnit() {
      return this.product.priceUnit;
    },
    quantityUnit() {
      return this.priceUnit || this.orderingUnit;
    },
    isQuantityUnitFractional() {
      return this.quantityUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    currencySymbol() {
      const { symbol } = (this.product && this.product.currency) || getCurrency() || {};
      return symbol;
    },
  },
  methods: {
    open() {
      this.tradeDealDraft = clone(this.product.deals ?? []);
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onSubmitSuccess({ data }) {
      Product.update({ data: data.data }).then(() => {
        this.close();
      });
      flash.success({ title: 'Successfully updated the trade deal for the product.' });
      this.$emit('success', data.data);
    },
    onSaveChangesClick() {
      this.submitForm();
    },
    onModalOpen() {
      this.$refs.modal.open();
    },
    formatUnitLabel(unitObj) {
      const { label } = unitObj || {};
      return label ? `${label}` : '';
    },
  },
};
</script>

<style scoped lang="scss">
.ez-tier-item {
  display: flex;
  flex-direction: column;

  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 12px;
  }

  :deep() .mask-input__input {
    font-weight: normal;
  }

  &__price-container {
    margin-top: 12px;
  }

  &__price {
    :deep() .input-group__prefix {
      border: 0;
      color: $color-gray-25;
      font-weight: normal;
    }

    :deep() .input-group__suffix {
      border: 0;
      color: $color-gray-25;
      font-weight: normal;
    }

    :deep() .input-group__input input {
      padding-left: 1.75rem;
    }
  }

  + .ez-tier-item {
    margin-top: 16px;
  }
}

.ez-tier-item__title {
  @include font-size(12px, 16px);
  font-weight: bold;
  text-transform: uppercase;
  color: $color-gray-6C;
}

.ez-tier-item__btn {
  @include font-size(12px, 14px);
  font-weight: 400;

  span {
    margin-left: 0.25rem;
  }
}
</style>
