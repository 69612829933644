<template>
  <ul class="tier-preview">
    <li v-for="tier in tiers" :key="tier.id">
      <span class="tier-preview__quantity" :data-cy="hasDataCy ? `${dataCyAttr('TEXT__QUANTITY')}-${tier.id}` : ''">
        From {{ tier.minQuantity }}
      </span>
      <v-price
        class="tier-preview__price"
        :price="tier.value || 0"
        :currency="currency"
        :is-market-price="tier.marketPrice"
        :show-market-price-info="true"
        :show-market-price-long="true"
        :data-cy="hasDataCy ? `${dataCyAttr('TEXT__PRICE')}-${tier.id}` : ''"
      />
    </li>
  </ul>
</template>

<script>
import VPrice from '@/components/v3/elements/VPrice';

export default {
  components: { VPrice },
  props: {
    tiers: {
      type: Array,
      default: () => [],
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
};
</script>

<style scoped lang="scss">
$item-height: 2.25rem;

.tier-preview {
  @include font-size(14px, 16px);
  @extend %ul-reset;
  li {
    @extend %flex-center;
    height: $item-height;
    justify-content: space-between;
  }

  &__price {
    :deep() .v-price__price {
      @include font-size(14px, 16px);
      color: $color-gray-6C;
      font-weight: normal;
    }
  }
}
</style>
