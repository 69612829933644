var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isProductLoaded)?_c('single-page',{scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [(!_vm.isAdmin)?[_c('router-link',{attrs:{"to":{ name: 'distributor-products' }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" Back to Products ")],1)]:[_c('router-link',{attrs:{"to":{ name: 'admin-distributors' }}},[_vm._v(" "+_vm._s(_vm.$t('global.distributors'))+" ")]),_c('span',[_vm._v("/")]),_c('router-link',{attrs:{"to":{ path: `/suppliers/${_vm.distributorId}` }}},[_vm._v(" "+_vm._s(_vm.distributor && _vm.distributor.name)+" ")]),_c('span',[_vm._v("/")]),_c('router-link',{attrs:{"to":{ path: `/suppliers/${_vm.distributorId}/products` }}},[_vm._v(" Products ")])]]},proxy:true},{key:"title",fn:function(){return [_c('ez-entity-info',{attrs:{"imgWidth":"2rem","imgHeight":"2rem","imgUrl":_vm.product.image}},[_c('h1',{staticClass:"limit-title",attrs:{"title":_vm.product.name}},[_vm._v(_vm._s(_vm.product.name))])])]},proxy:true},{key:"actions",fn:function(){return [(_vm.canCreateDeleteProduct)?_c('ez-button-dropdown',{attrs:{"buttonType":"secondary"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('ez-button',{attrs:{"type":"red-link"},on:{"click":_vm.openDeleteModal}},[_vm._v("Delete Product ")])]},proxy:true}],null,false,3252840364)}):_vm._e()]},proxy:true},{key:"navigation",fn:function(){return [_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
              name: _vm.isAdmin ? 'admin-distributor-product-info' : 'distributor-product-single',
              params: { ...(_vm.isAdmin ? { productId: _vm.product.id } : {}) },
            }}},[_vm._v(" Product Info ")])],1),(!_vm.isAdmin && _vm.me.inventoryTracking)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'distributor-product-inventory-history' }}},[_vm._v(" Inventory History ")])],1):_vm._e(),(!_vm.isAdmin && _vm.me.inventoryTracking)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'distributor-product-inventory-overview' }}},[_vm._v(" Inventory Overview ")])],1):_vm._e(),(!_vm.isAdmin && _vm.me.inventoryTracking)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'distributor-product-par-level' }}},[_vm._v(" Inventory Par Level ")])],1):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{
              name: _vm.isAdmin
                ? 'admin-distributor-product-tiers'
                : 'distributor-product-single-tier',
              params: { ...(_vm.isAdmin ? { productId: _vm.product.id } : {}) },
            }}},[_vm._v("Pricing ")])],1),(_vm.canHideProducts)?_c('li',[_c('router-link',{attrs:{"to":{
              name: _vm.isAdmin
                ? 'admin-distributor-product-availability'
                : 'distributor-product-availability',
              params: { ...(_vm.isAdmin ? { productId: _vm.product.id } : {}) },
            }}},[_vm._v("Availability ")])],1):_vm._e()])]},proxy:true}],null,false,2918453751)},[_c('ez-confirmation-modal',{ref:"deleteModal",attrs:{"type":"red"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Delete this product?")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v("When deleted, this product will no longer be live on "+_vm._s(_vm.platformName)+".")])]},proxy:true},{key:"footer",fn:function(){return [_c('ez-button',{attrs:{"type":"link"},on:{"click":_vm.closeDeleteModal}},[_vm._v("Cancel")]),_c('ez-button',{attrs:{"type":"red"},on:{"click":_vm.confirmDeleteProduct}},[_vm._v("Yes, Delete")])]},proxy:true}],null,false,747145715)})],1):_vm._e(),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v("Loading...")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }