<template>
  <div>
    <ez-user-list
      :role="userRole"
      :users="[{
        ...this.loggedUser,
        role: {
          ...this.loggedUser.role,
          name: 'Admin',
        }

      }]"
      :columns="['name', 'email', 'role']"
      :columnProps="{
        role: { class: 'cell-role' },
      }"
      :isEditable="false"
      :canAddNewUser="false"
    >
    </ez-user-list>
    <div class="has-admin-note">
      <font-awesome-icon icon="info-circle" />
      <span>Note: You will be able to add more users later within the account.</span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzUserList from '@/views/common/users';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('entities/distributors', ['draft']),
    userRole() {
      return localStorage.getItem('role');
    },
  },
  methods: {
    ...mapMutations('entities/distributors', ['UPDATE_DRAFT']),
    onNextStep() {
      this.UPDATE_DRAFT({ users: [this.loggedUser] });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
  },
  mounted() {
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
:deep() .table {
  .cell-role {
    text-align: right;
  }
}

.ez-distributor-manager-permissions {
  @include separator();
}

.has-admin-note {
  @extend %flex-center;
  justify-content: center;
  margin-top: px-to-rem(16px);
  color: $color-gray-6C;
  svg {
    color: #b9bfcd;
    margin-right: px-to-rem(6px);
  }
  span {
    @include font-size(14px);
    font-weight: 400;
  }
}
</style>
