import products from '@/store/modules/products';
import { isAdmin, isDistributor } from '@/util/constants';

const { actions } = products;

/**
 * Gets Units for Distributor & Admin context.
 *
 * @param {number} distributorId
 * @param {string} term
 * @param {string} group
 * @param {string} loadingKey
 *
 * @return {Promise<*>}
 */
export async function getUnits({ distributorId, term, group, loadingKey } = {}) {
  try {
    let res;
    if (isDistributor()) {
      res = await actions.distributorFetchUnits();
    } else if (isAdmin()) {
      res = await actions.adminFetchUnits({
        distributorId,
        term,
        group,
        ...(loadingKey && { loadingKey }),
      });
    } else {
      throw new Error('Fn: getUnits - Not implemented for this context.');
    }
    return res;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return err;
  }
}
