<script>
/**
 * VTagSelectSearch
 * @version 1.0.0
 * @since
 */

import EzInput from '@/components/ui/Input';
import { debounce } from '@/util/utils';
import VTag from '@/components/v3/elements/VTag';
import EzButton from '@/components/ui/Button';
import { NUMBER_OF_TAGS_TO_DISPLAY, TAGS_DISPLAY_TRESHOLD } from '@/util/constants';

export default {
  components: {
    EzInput,
    VTag,
    EzButton,

  },
  props: {
    selectedTags: {
      required: false,
      type: Array,
      default: () => [],
    },
    unselectedTags: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      term: '',
      tagsListExpanded: false,
    };
  },
  computed: {
    filteredTags() {
      return this.unselectedTags.filter(tag => tag.name.toLowerCase().includes(this.term.toLowerCase()));
    },
    numberOfTagsToDisplay() {
      if (this.filteredTags.length - NUMBER_OF_TAGS_TO_DISPLAY > TAGS_DISPLAY_TRESHOLD) return NUMBER_OF_TAGS_TO_DISPLAY;
      return this.filteredTags.length;
    },
    filteredTagsToDisplay() {
      if (!this.tagsListExpanded) {
        return this.filteredTags.slice(0, this.numberOfTagsToDisplay);
      }
      return this.filteredTags;
    },
    expandButtonCopy() {
      return this.tagsListExpanded ? 'Collapse List' : 'Show more tags';
    },
    expandButtonIcon() {
      return this.tagsListExpanded ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    resetSearch() {
      this.term = '';
    },
    onClick(tag, isUnselect) {
      this.$emit('change', {
        tag,
        isUnselect,
      });
    },
    onSearch: debounce(async function deb(term) {
      if (term !== this.term) {
        this.term = term;
      }
    }, 400),
    toggleListExpanded() {
      this.tagsListExpanded = !this.tagsListExpanded;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="selectedTags.length" class="tags-wrapper tags-wrapper__selected">
      <div class="tags-label">
        <span class="tags-label__number">{{ selectedTags.length }}</span> selected {{ 'tag' | pluralize(selectedTags.length) }}
      </div>
      <div class="all-tags">
        <v-tag v-for="tag in selectedTags" :key=tag.id :color=tag.color.colors[0] :backgroundColor=tag.color.colors[1]
          @click="onClick(tag, true)" :canRemove=true>
          {{ tag.name }}
        </v-tag>
      </div>
    </div>
    <div class="tags-wrapper">
      <form @submit.prevent>
        <ez-input v-if="unselectedTags.length" :value="term" formKey="filters" name="search" label="All Tags" class="search"
          :placeholder="`Search for a ${$t('global.tag')}`" @onChange="onSearch">
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </form>
      <div v-if="filteredTags.length" class="all-tags">
        <v-tag
          v-for="tag in filteredTagsToDisplay"
          :key=tag.id :color=tag.color.colors[0]
          :backgroundColor=tag.color.colors[1]
          @click="onClick(tag)">
          {{ tag.name }}
        </v-tag>
        <span
          class="tags-number-indicator"
          v-if="filteredTags.length > numberOfTagsToDisplay && !tagsListExpanded"
        >
          +{{ filteredTags.length - numberOfTagsToDisplay }}
        </span>
      </div>
      <ez-button
        v-if="filteredTags.length > numberOfTagsToDisplay"
        class="u-flex-h-center mt-16"
        @click="toggleListExpanded"
        isFullWidth
        type="link"
        formType="button"
      >
        <span>{{ expandButtonCopy }}</span>
        <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
      </ez-button>
      <div v-if="!filteredTags.length" class="tags-label">
        No more tags to show
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tags-label {
  @include font-size($label-font-size, 18px);
  color: $color-gray-6C;
  margin-bottom: 16px;

  &__number {
    font-weight: 700;
  }
}

.tags-number-indicator {
  @include font-size(14px, 16px);
  color: $color-gray-6C;
}

.all-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.tags-wrapper {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dashed $color-gray-E1;
}
</style>
